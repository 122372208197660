import React, { ReactElement } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { GlobalThemeWrapper } from 'components/organisms/GlobalThemeWrapper';
import { Overlay } from 'components/organisms/Overlay';
import { TSGlobalHeader } from 'components/organisms/TSGlobalHeader';
import AlertProvider from 'context/Alert/AlertContext';
import AuthenticationStateProvider from 'context/AuthenticationState/AuthenticationStateContext';
import CertificateStateProvider from 'context/CertificateState/CertificateStateContext';
import ClaimAdjudicationConfigsProvider from 'context/ClaimAdjudicationConfigs/ClaimAdjudicationConfigsContext';
import ClaimEditProvider from 'context/ClaimEdit/ClaimEditContext';
import ClaimFormAdvancementProvider from 'context/ClaimFormAdvancement/ClaimFormAdvancementContext';
import ClaimFormDisabilityProvider from 'context/ClaimFormDisability/ClaimFormDisabilityContext';
import ClaimFormJobLossProvider from 'context/ClaimFormJobLoss/ClaimFormJobLossContext';
import ClaimFormResponseProvider from 'context/ClaimFormResponse/ClaimFormResponseContext';
import ClaimLookupProvider from 'context/ClaimLookup/ClaimLookupContext';
import ClaimManagementProvider from 'context/ClaimManagement/ClaimManagementContext';
import ClaimOptionProvider from 'context/ClaimOption/ClaimOptionContext';
import ClaimProgressDisabilityProvider from 'context/ClaimProgressDisability/ClaimProgressDisabilityContext';
import ClaimProgressJobLossProvider from 'context/ClaimProgressJobLoss/ClaimProgressJobLossContext';
import DistributorDataProvider from 'context/DistributorData/DistributorDataContext';
import InternalDataRequiredProvider from 'context/InternalDataRequired/InternalDataRequiredContext';
import OverlayProvider from 'context/Overlay/OverlayContext';
import { oktaAuthClient } from 'OktaAuth';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { RouterSwitch } from 'routes';
import 'styles/styles.css';

function App(): ReactElement {
    const history = useHistory();
    const restoreOriginalUri = async (_oktaAuth, originalUri): Promise<void> => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };
    /*
    console.info(
        '\n:::::::::::::::::::::::::::::APP::::::::::::::::::::::::::::::::::::',
        '\n::process.env.NODE_ENV::',
        process.env.NODE_ENV,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );
    */

    return (
        <AuthenticationStateProvider>
            <ClaimOptionProvider>
                <ClaimFormJobLossProvider>
                    <ClaimFormDisabilityProvider>
                        <ClaimProgressJobLossProvider>
                            <ClaimProgressDisabilityProvider>
                                <DistributorDataProvider>
                                    <ClaimLookupProvider>
                                        <ClaimManagementProvider>
                                            <ClaimAdjudicationConfigsProvider>
                                                <ClaimEditProvider>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <OverlayProvider>
                                                            <AlertProvider>
                                                                <InternalDataRequiredProvider>
                                                                    <ClaimFormAdvancementProvider>
                                                                        <ClaimFormResponseProvider>
                                                                            <CertificateStateProvider>
                                                                                <Security
                                                                                    oktaAuth={oktaAuthClient}
                                                                                    restoreOriginalUri={
                                                                                        restoreOriginalUri
                                                                                    }
                                                                                >
                                                                                    <GlobalThemeWrapper>
                                                                                        <Overlay>
                                                                                            <Router>
                                                                                                <TSGlobalHeader></TSGlobalHeader>
                                                                                                <RouterSwitch />
                                                                                            </Router>
                                                                                        </Overlay>
                                                                                    </GlobalThemeWrapper>
                                                                                </Security>
                                                                            </CertificateStateProvider>
                                                                        </ClaimFormResponseProvider>
                                                                    </ClaimFormAdvancementProvider>
                                                                </InternalDataRequiredProvider>
                                                            </AlertProvider>
                                                        </OverlayProvider>
                                                    </LocalizationProvider>
                                                </ClaimEditProvider>
                                            </ClaimAdjudicationConfigsProvider>
                                        </ClaimManagementProvider>
                                    </ClaimLookupProvider>
                                </DistributorDataProvider>
                            </ClaimProgressDisabilityProvider>
                        </ClaimProgressJobLossProvider>
                    </ClaimFormDisabilityProvider>
                </ClaimFormJobLossProvider>
            </ClaimOptionProvider>
        </AuthenticationStateProvider>
    );
}

const AppWithRouterAccess = (): ReactElement => (
    <Router>
        <App />
    </Router>
);
export default AppWithRouterAccess;
