import React, { ReactElement } from 'react';
import { SCAutoCompleteWrapper } from './styles';
import { ITSAutocompleteProps } from './types';
import { Autocomplete, TextField } from '@mui/material';

/***
 * Our wrapper around the MaterialUI Autocomplete component (aka, a dropdown selection component with type-and-search functionality)
 */
const TSAutocomplete = ({
    options,
    label,
    id,
    defaultValue,
    value,
    getOptionLabel,
    onChange,
    disabled,
    autoHighlight,
    filterOptions,
    dataTestId,
    renderOption,
    placeholder,
    onInputChange,
    onFreeSolo,
    autoSelect,
    ariaLabel
}: ITSAutocompleteProps): ReactElement => {
    /*console.info(
        '\n::::::::::::::::::::::::::::::::TSAutocomplete:::::::::::::::::::::::::::::::::',
        '\n::id::',
        id,
        '\n::options::',
        options,
        '\n::value::',
        value,
        '\n::defaultValue::',
        defaultValue,
        '\n::autoSelect::',
        autoSelect,
        '\n::autoHighlight::',
        autoHighlight,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );*/

    return (
        <SCAutoCompleteWrapper>
            <Autocomplete
                disablePortal
                defaultValue={defaultValue ? defaultValue : value ?? undefined}
                value={value ?? undefined}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={(option, value): boolean => {
                    return value === undefined || value === '' || option.id === value.id;
                }}
                options={options}
                renderInput={(params): ReactElement => (
                    <TextField
                        name="autocompleteInput"
                        {...params}
                        label={label}
                        placeholder={placeholder}
                        inputProps={{ ...params.inputProps }}
                        variant="standard"
                    />
                )}
                renderOption={renderOption}
                id={id}
                aria-label={ariaLabel}
                onChange={onChange}
                disabled={disabled}
                autoHighlight={autoHighlight}
                filterOptions={filterOptions}
                data-testid={dataTestId}
                onInputChange={onInputChange}
                freeSolo={onFreeSolo}
                autoSelect={autoSelect}
            />
        </SCAutoCompleteWrapper>
    );
};

export { TSAutocomplete };
