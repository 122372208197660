import * as React from 'react';
import { IClaimProgressDisabilityTypeState, IClaimProgressDisabilityType } from './ClaimProgressDisabilityTypes';
import { IChildrenProps } from 'types/children';
import { TimelineState } from 'enums';

export const ClaimProgressDisabilityContext = React.createContext<IClaimProgressDisabilityTypeState | null>(null);
const ClaimProgressDisabilityProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialState = [
        {
            id: 'id-1',
            label: 'Personal Information',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-2',
            label: 'Disability Details',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-3',
            label: 'Employer Information',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-4',
            label: 'Healthcare Information',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-5',
            label: 'Comments (optional)',
            status: TimelineState.INCOMPLETE
        }
    ];
    const [stateClaimProgressDisability, setStateClaimProgressDisability] =
        React.useState<IClaimProgressDisabilityType[]>(initialState);
    const [stateClaimProgressDisabilityCompletionStatus, setStateClaimProgressDisabilityCompletionStatus] =
        React.useState(false);
    const updateFormDisabilityProgressState = (id: string, status: TimelineState): void => {
        stateClaimProgressDisability.filter((formData) => {
            if (formData.id === id) {
                formData.status = status;
                setStateClaimProgressDisability([...stateClaimProgressDisability]);
            }
        });
    };
    const resetFormDisabilityProgressState = (): void => {
        setStateClaimProgressDisability(initialState);
    };
    const updateFormDisabilityProgressCompletionStatus = (status: boolean): void => {
        setStateClaimProgressDisabilityCompletionStatus(status);
    };
    const updateAllFormDisabilityProgressStatusComplete = (): void => {
        stateClaimProgressDisability.map((obj) => {
            obj.status = TimelineState.COMPLETE;
            setStateClaimProgressDisability([...stateClaimProgressDisability]);
        });
    };

    const stateObject: IClaimProgressDisabilityTypeState = {
        contextStateClaimProgressDisability: stateClaimProgressDisability,
        updateContextStateClaimProgressDisability: updateFormDisabilityProgressState,
        resetContextStateClaimProgressDisability: resetFormDisabilityProgressState,
        claimProgressDisabilityCompletionStatus: stateClaimProgressDisabilityCompletionStatus,
        updateClaimProgressDisabilityCompletionStatus: updateFormDisabilityProgressCompletionStatus,
        updateAllClaimProgressDisabilityCompletionStatus: updateAllFormDisabilityProgressStatusComplete
    };
    return (
        <ClaimProgressDisabilityContext.Provider value={stateObject}>
            {children}
        </ClaimProgressDisabilityContext.Provider>
    );
};

export default ClaimProgressDisabilityProvider;
