import React, { ReactElement, SyntheticEvent, useEffect, useRef } from 'react';
import { SCBodyLarge, SCContentSectionWrapper, SCFormRefWrapper, SCSubHead } from 'styles/global-styles';
import {
    SCErrorLabel,
    SCInfoLabel,
    SCSearchClaimButton,
    SCFormWrapper,
    SCInputWrapper,
    SCElementsWrapper
} from './styles';
import { IFormClaimLookupProps } from './types';
import { errorMapIU, labelMapIU } from './fieldTypes';
import { ClaimLookupService } from 'api/ClaimLookup';
import { validateLast4SSN } from 'utils/form/form';
import { TSTextField } from 'components/atoms/TSTextField';
import { TSButton } from 'components/atoms/TSButton';
import { FlexAlignItemsEnum, FlexDirectionEnum } from 'enums/StyleTypes';
import { isPhoneIdentifierType } from 'utils/helpers/helpers';
import { DistributorDataContext, IDistributorDataTypeState } from 'context/DistributorData';
import {
    ClaimLookupContext,
    IClaimLookupTypeState,
    IClaimLookupRequestData,
    IClaimLookupType
} from 'context/ClaimLookup';

const FormClaimLookup = ({ children }: IFormClaimLookupProps): ReactElement => {
    const fieldRef = useRef<HTMLInputElement>(null);
    const [formData, setFormData] = React.useState<IClaimLookupRequestData | Record<string, unknown>>();
    const [ClaimNotFoundMessage, setClaimNotFoundMessage] = React.useState<string | null>(null);
    const [ssnInputType, setSsnInputType] = React.useState<string>('text');
    const [ssnInputError, setSsnInputError] = React.useState<string | null>(null);
    const [claimNumberInputError, setClaimNumberInputError] = React.useState<string | null>(null);
    const { contextStateClaimLookup, updateContextStateClaimLookup } = React.useContext(
        ClaimLookupContext
    ) as IClaimLookupTypeState;
    const { contextStateDistributorData, updateContextStateDistributorData } = React.useContext(
        DistributorDataContext
    ) as IDistributorDataTypeState;
    const [isBorrowerIdentifierPhone, setIsBorrowerIdentifierPhone] = React.useState(false);
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            setClaimNumberInputError('');
            setSsnInputError('');

            if (contextStateDistributorData) {
                setIsBorrowerIdentifierPhone(
                    isPhoneIdentifierType(contextStateDistributorData.borrowerIdentifierType as number)
                );
                setFormData({
                    ...formData,
                    ['distributorName']: contextStateDistributorData.distributorName
                });
            }
            firstUpdate.current = false;
            return;
        }
    }, []);
    const handleClaimNumberInput = (name: string, value: string): void => {
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleSSNInput = (name: string, value: string): void => {
        value.length > 0 && !validateLast4SSN(value)
            ? setSsnInputError(errorMapIU['borrowerIdentifierKeyError'])
            : setSsnInputError(null);
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const prepareAndPost = async (formData: any): Promise<void> => {
        setClaimNotFoundMessage('');
        ClaimLookupService.postClaimWithResponse(formData)
            .then((res) => {
                if (res.data.payload) {
                    const data = res.data.payload as IClaimLookupType;
                    data.shouldDisplayResult = true;
                    updateContextStateClaimLookup(data);
                } else {
                    contextStateClaimLookup.shouldDisplayNoResult = true;
                    updateContextStateClaimLookup(contextStateClaimLookup);
                }
            })
            .catch((error) => {
                setClaimNotFoundMessage('Something went wrong !! Please try again later');
            });
    };

    return (
        <SCFormRefWrapper ref={fieldRef}>
            <SCContentSectionWrapper
                flexDirection={FlexDirectionEnum.COLUMN}
                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                flexGap={'24px'}
                sectionWidth={'90%'}
            >
                <SCSubHead>Check Claim Status</SCSubHead>
                <SCContentSectionWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                >
                    <SCBodyLarge>
                        Enter your personal information here so that we can search for your claims records.
                    </SCBodyLarge>
                    <SCBodyLarge>All fields are required unless otherwise indicated.</SCBodyLarge>
                </SCContentSectionWrapper>
                <SCFormWrapper>
                    <SCElementsWrapper>
                        <SCInputWrapper>
                            <TSTextField
                                id={'claimNumber'}
                                label={labelMapIU['claimNumber']}
                                value={formData?.claimNumber as string}
                                ariaLabel="claimNumber"
                                onChange={(formEvent: React.FormEvent<HTMLInputElement>): void => {
                                    handleClaimNumberInput('claimNumber', formEvent.currentTarget.value);
                                }}
                                onFocus={(formEvent: React.FormEvent<HTMLInputElement>): void => {
                                    setClaimNotFoundMessage('');
                                }}
                                placeholder={labelMapIU['claimNumber']}
                                maxLength={10}
                            />
                            <SCInfoLabel />
                        </SCInputWrapper>
                        <SCInputWrapper>
                            <TSTextField
                                id={'borrowerIdentifierKey'}
                                label={
                                    isBorrowerIdentifierPhone
                                        ? labelMapIU['borrowerIdentifierPhone']
                                        : labelMapIU['borrowerIdentifierSsn']
                                }
                                type={ssnInputType}
                                value={formData?.borrowerIdentifierKey as string}
                                ariaLabel="borrowerIdentifierKey"
                                onChange={(formEvent: React.FormEvent<HTMLInputElement>): void => {
                                    handleSSNInput('borrowerIdentifierKey', formEvent.currentTarget.value);
                                }}
                                onFocus={(event): void => {
                                    setClaimNotFoundMessage('');
                                    setSsnInputType('text');
                                }}
                                onBlur={(event): void => {
                                    setSsnInputType('password');
                                }}
                                placeholder={`Enter ${
                                    isBorrowerIdentifierPhone
                                        ? labelMapIU['borrowerIdentifierPhone']
                                        : labelMapIU['borrowerIdentifierSsn']
                                }`}
                                maxLength={4}
                            />
                            <SCInfoLabel>We need this to find your claim</SCInfoLabel>
                        </SCInputWrapper>
                        {ssnInputError && <SCErrorLabel>{ssnInputError}</SCErrorLabel>}
                        {ClaimNotFoundMessage && <SCErrorLabel>{ClaimNotFoundMessage}</SCErrorLabel>}
                        <SCSearchClaimButton>
                            {' '}
                            <TSButton
                                id="submitClaimLookupBtn"
                                ariaLabel="submitClaimLookupBtn"
                                disabled={!!ssnInputError}
                                onClick={(event: SyntheticEvent): void => {
                                    prepareAndPost(formData);
                                }}
                            >
                                Submit
                            </TSButton>
                        </SCSearchClaimButton>
                    </SCElementsWrapper>
                </SCFormWrapper>
            </SCContentSectionWrapper>
        </SCFormRefWrapper>
    );
};

export { FormClaimLookup };
