import React, { ReactElement, useEffect } from 'react';
import { FormDisability } from 'components/organisms/FormDisability';
import { GlobalFooter } from 'components/organisms/GlobalFooter';
import { ClaimTypesEnum, TimelineState, TrackerEnum } from 'enums';
import { IClaimFormProps } from './types';
import { SCContactInfoBottom, SCContactInfoTop, SCDecisionContent, SCDecisionItem } from './styles';
import {
    $darkGray,
    SCBodyLarge,
    SCContentDirectionWrapper,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCContentWrapper,
    SCFooterWrapper,
    SCHeroHeader,
    SCPageWrapper,
    SCSectionBody,
    SCSectionSubHead,
    SCSubHead
} from 'styles/global-styles';
import { FormIU } from 'components/organisms/FormIU';
import { ConfirmAndSubmit } from 'components/molecules/ConfirmAndSubmit';
import { SCButtonWrapper } from 'components/atoms/TSButton/styles';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { ClaimConfirmation } from 'components/organisms/ClaimConfirmation';
import { ClaimStateEnum } from 'enums/ClaimState';
import { ClaimFormAdvancementContext, IClaimFormAdvancementTypeState } from 'context/ClaimFormAdvancement';
import { FAQSummary } from 'components/organisms/FAQSummary';
import { FAQGroupName } from 'enums/FAQGroupName';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum } from 'enums/StyleTypes';
import { ClaimOptionContext, IClaimOptionTypeState } from 'context/ClaimOption';
import { ClaimFormDisabilityContext, IClaimFormDisabilityTypeState } from 'context/ClaimFormDisability';
import { ClaimFormJobLossContext, IClaimFormJobLossTypeState } from 'context/ClaimFormJobLoss';
import { ClaimProgressJobLossContext, IClaimProgressJobLossTypeState } from 'context/ClaimProgressJobLoss';
import { ClaimProgressDisabilityContext, IClaimProgressDisabilityTypeState } from 'context/ClaimProgressDisability';
import { DistributorDataContext, IDistributorDataTypeState } from 'context/DistributorData';

const ClaimForm = ({ children }: IClaimFormProps): ReactElement => {
    const { contextStateClaimOption, updateContextStateClaimOption } = React.useContext(
        ClaimOptionContext
    ) as IClaimOptionTypeState;
    const {
        contextStateClaimFormJobLoss,
        updateContextStateClaimFormJobLoss,
        reinitialiseContextStateClaimFormJobLoss
    } = React.useContext(ClaimFormJobLossContext) as IClaimFormJobLossTypeState;
    const {
        contextStateClaimFormDisability,
        updateContextStateClaimFormDisability,
        reinitialiseStateClaimFormDisability
    } = React.useContext(ClaimFormDisabilityContext) as IClaimFormDisabilityTypeState;
    const {
        updateContextStateClaimProgressJobLoss,
        contextStateClaimProgressJobLoss,
        resetContextStateClaimProgressJobLoss,
        updateClaimProgressJobLossCompletionStatus,
        claimProgressJobLossCompletionStatus,
        updateAllClaimProgressJobLossCompletionStatus
    } = React.useContext(ClaimProgressJobLossContext) as IClaimProgressJobLossTypeState;
    const {
        contextStateClaimProgressDisability,
        updateContextStateClaimProgressDisability,
        resetContextStateClaimProgressDisability,
        claimProgressDisabilityCompletionStatus,
        updateClaimProgressDisabilityCompletionStatus,
        updateAllClaimProgressDisabilityCompletionStatus
    } = React.useContext(ClaimProgressDisabilityContext) as IClaimProgressDisabilityTypeState;
    const { contextStateDistributorData, updateContextStateDistributorData } = React.useContext(
        DistributorDataContext
    ) as IDistributorDataTypeState;
    const { contextStateClaimFormAdvancement, updateContextStateClaimFormAdvancement } = React.useContext(
        ClaimFormAdvancementContext
    ) as IClaimFormAdvancementTypeState;
    useEffect(() => {
        updateContextStateClaimOption({ type: undefined, reviewState: ClaimStateEnum.ACTIVE });
        reinitialiseContextStateClaimFormJobLoss();
        reinitialiseStateClaimFormDisability();
        resetContextStateClaimProgressJobLoss();
        resetContextStateClaimProgressDisability();
        updateClaimProgressJobLossCompletionStatus(false);
        updateClaimProgressDisabilityCompletionStatus(false);
    }, []);
    /*
    console.info(
        '\n::::::::::::::::::::::::::::::::ClaimForm:::::::::::::::::::::::::::::::::',
        '\n::contextStateClaimOption::',
        contextStateClaimOption,
        '\n::contextStateClaimFormAdvancement::',
        contextStateClaimFormAdvancement,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );
    */

    return (
        <SCPageWrapper>
            <SCContentWrapper>
                <SCContentDirectionWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                >
                    {(ClaimTypesEnum.Jobloss === contextStateClaimOption.type && (
                        <SCContentSectionWrapper
                            flexDirection={FlexDirectionEnum.COLUMN}
                            flexAlignment={FlexAlignItemsEnum.CENTER}
                            sectionWidth={'100%'}
                        >
                            {contextStateClaimFormAdvancement.reviewState === ClaimStateEnum.ACTIVE && (
                                <FormIU
                                    changeFormType={(): void => {
                                        updateContextStateClaimOption({
                                            type: ClaimTypesEnum.Disability,
                                            reviewState: ClaimStateEnum.ACTIVE
                                        });
                                        updateContextStateClaimFormAdvancement({
                                            reviewState: ClaimStateEnum.ACTIVE
                                        });
                                        updateContextStateClaimProgressDisability('id-1', TimelineState.ACTIVE);
                                    }}
                                />
                            )}
                            {contextStateClaimFormAdvancement.reviewState === ClaimStateEnum.REVIEW && (
                                <ConfirmAndSubmit
                                    formState={contextStateClaimFormJobLoss}
                                    saveFormState={updateContextStateClaimFormJobLoss}
                                />
                            )}
                            {contextStateClaimFormAdvancement.reviewState === ClaimStateEnum.CONFIRMATION && (
                                <ClaimConfirmation />
                            )}
                        </SCContentSectionWrapper>
                    )) ||
                        (ClaimTypesEnum.Disability === contextStateClaimOption.type && (
                            <SCContentSectionWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.CENTER}
                                sectionWidth={'100%'}
                            >
                                {contextStateClaimFormAdvancement.reviewState === ClaimStateEnum.ACTIVE && (
                                    <FormDisability
                                        changeFormType={(): void => {
                                            updateContextStateClaimOption({
                                                type: ClaimTypesEnum.Jobloss,
                                                reviewState: ClaimStateEnum.ACTIVE
                                            });
                                            updateContextStateClaimFormAdvancement({
                                                reviewState: ClaimStateEnum.ACTIVE
                                            });
                                            updateContextStateClaimProgressJobLoss('id-1', TimelineState.ACTIVE);
                                        }}
                                    />
                                )}
                                {contextStateClaimFormAdvancement.reviewState === ClaimStateEnum.REVIEW && (
                                    <ConfirmAndSubmit
                                        formState={contextStateClaimFormDisability}
                                        saveFormState={updateContextStateClaimFormDisability}
                                    />
                                )}
                                {contextStateClaimFormAdvancement.reviewState === ClaimStateEnum.CONFIRMATION && (
                                    <ClaimConfirmation />
                                )}
                            </SCContentSectionWrapper>
                        )) ||
                        (typeof contextStateClaimOption.type === 'undefined' && (
                            <SCContentSectionResponsiveWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                flexGap={'80px'}
                            >
                                <SCContentSectionWrapper
                                    flexDirection={FlexDirectionEnum.COLUMN}
                                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                    flexGap={'36px'}
                                >
                                    <SCContentSectionWrapper
                                        flexDirection={FlexDirectionEnum.ROW}
                                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                        flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                        flexGap={'0'}
                                    >
                                        <SCHeroHeader>File a Claim</SCHeroHeader>
                                        <SCContentSectionWrapper
                                            flexDirection={FlexDirectionEnum.COLUMN}
                                            flexAlignment={FlexAlignItemsEnum.FLEX_END}
                                            flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                            flexGap={'0'}
                                            sectionWidth={'25%'}
                                        >
                                            <SCContactInfoTop>
                                                <h3> 1-855-997-7272</h3>
                                                <h4> 8:00 AM - 5:00 PM CT</h4>
                                                <h4>Monday - Friday</h4>
                                            </SCContactInfoTop>
                                        </SCContentSectionWrapper>
                                    </SCContentSectionWrapper>
                                    {contextStateDistributorData?.client_content &&
                                        contextStateDistributorData.client_content.claim_options.length > 1 && (
                                            <SCContentSectionWrapper
                                                flexDirection={FlexDirectionEnum.COLUMN}
                                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                                flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                                flexGap={'24px'}
                                                sectionWidth={'80%'}
                                            >
                                                <SCSubHead textColor={$darkGray}>
                                                    First, let us know if you are filing for disability or job loss
                                                </SCSubHead>
                                                <SCBodyLarge>
                                                    Choose from the list below to help us identify which claims
                                                    experience to guide you to.{' '}
                                                </SCBodyLarge>
                                            </SCContentSectionWrapper>
                                        )}
                                    <SCContentSectionWrapper
                                        flexDirection={FlexDirectionEnum.ROW}
                                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                        flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                        flexGap={'24px'}
                                        sectionWidth={'80%'}
                                    >
                                        {contextStateDistributorData?.client_content?.claim_options.find(
                                            (x) => x.value === ClaimTypesEnum.Disability
                                        ) && (
                                            <SCDecisionContent widthProps={'50%'}>
                                                <SCDecisionItem>
                                                    <SCContentSectionWrapper
                                                        flexDirection={FlexDirectionEnum.COLUMN}
                                                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                                        flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                                        flexGap={'30px'}
                                                    >
                                                        <SCSectionSubHead>
                                                            I need to file a claim for a Disability incident
                                                        </SCSectionSubHead>
                                                        <SCSectionBody>
                                                            I have experienced a disability incident which affected my
                                                            employment.
                                                        </SCSectionBody>
                                                        <SCButtonWrapper>
                                                            <TSButton
                                                                tsVariant={TSButtonVariantsEnum.ARROW}
                                                                ariaLabel={''}
                                                                onClick={(): void => {
                                                                    updateContextStateClaimOption({
                                                                        type: ClaimTypesEnum.Disability,
                                                                        reviewState: ClaimStateEnum.ACTIVE
                                                                    });
                                                                    updateContextStateClaimFormAdvancement({
                                                                        reviewState: ClaimStateEnum.ACTIVE
                                                                    });
                                                                    updateContextStateClaimProgressDisability(
                                                                        'id-1',
                                                                        TimelineState.ACTIVE
                                                                    );
                                                                    document.dispatchEvent(
                                                                        new CustomEvent(
                                                                            TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS,
                                                                            {
                                                                                detail: {
                                                                                    claimType:
                                                                                        ClaimTypesEnum.Disability,
                                                                                    distributorName:
                                                                                        contextStateDistributorData.distributorName,
                                                                                    id: TrackerEnum.CLAIMSTEP_START
                                                                                }
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                File a Disability Claim
                                                            </TSButton>
                                                        </SCButtonWrapper>
                                                    </SCContentSectionWrapper>
                                                </SCDecisionItem>
                                            </SCDecisionContent>
                                        )}
                                        {contextStateDistributorData?.client_content?.claim_options.find(
                                            (x) => x.value === ClaimTypesEnum.Jobloss
                                        ) && (
                                            <SCDecisionContent widthProps={'50%'}>
                                                <SCDecisionItem>
                                                    <SCContentSectionWrapper
                                                        flexDirection={FlexDirectionEnum.COLUMN}
                                                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                                        flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                                        flexGap={'30px'}
                                                    >
                                                        <SCSectionSubHead>
                                                            I need to file a claim for Job Loss
                                                        </SCSectionSubHead>
                                                        <SCSectionBody>
                                                            I have experienced involuntary unemployment or job loss.{' '}
                                                        </SCSectionBody>
                                                        <SCButtonWrapper>
                                                            <TSButton
                                                                tsVariant={TSButtonVariantsEnum.ARROW}
                                                                ariaLabel={''}
                                                                onClick={(): void => {
                                                                    updateContextStateClaimOption({
                                                                        type: ClaimTypesEnum.Jobloss,
                                                                        reviewState: ClaimStateEnum.ACTIVE
                                                                    });
                                                                    updateContextStateClaimFormAdvancement({
                                                                        reviewState: ClaimStateEnum.ACTIVE
                                                                    });
                                                                    updateContextStateClaimProgressJobLoss(
                                                                        'id-1',
                                                                        TimelineState.ACTIVE
                                                                    );
                                                                    document.dispatchEvent(
                                                                        new CustomEvent(
                                                                            TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS,
                                                                            {
                                                                                detail: {
                                                                                    claimType: ClaimTypesEnum.Jobloss,
                                                                                    distributorName:
                                                                                        contextStateDistributorData.distributorName,
                                                                                    id: TrackerEnum.CLAIMSTEP_START
                                                                                }
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                File a Job Loss Claim
                                                            </TSButton>
                                                        </SCButtonWrapper>
                                                    </SCContentSectionWrapper>
                                                </SCDecisionItem>
                                            </SCDecisionContent>
                                        )}
                                    </SCContentSectionWrapper>
                                    <SCContactInfoBottom>
                                        <h3> 1-855-997-7272</h3>
                                        <h4> 8:00 AM - 5:00 PM CT</h4>
                                        <h4>Monday - Friday</h4>
                                    </SCContactInfoBottom>
                                </SCContentSectionWrapper>
                                {contextStateDistributorData?.client_content && (
                                    <FAQSummary
                                        distributorConfigurationData={contextStateDistributorData.client_content}
                                        groupName={FAQGroupName.claim}
                                    ></FAQSummary>
                                )}
                            </SCContentSectionResponsiveWrapper>
                        ))}
                </SCContentDirectionWrapper>
            </SCContentWrapper>
            <SCFooterWrapper>
                <GlobalFooter />
            </SCFooterWrapper>
        </SCPageWrapper>
    );
};

export { ClaimForm };
