import React, { ReactElement } from 'react';
import {
    SCContentSectionWrapper,
    SCContentSectionResponsiveWrapper,
    SCContentWrapper,
    SCFooterWrapper,
    SCPageWrapper
} from 'styles/global-styles';
import { IClaimLookupProps } from './types';
import { GlobalFooter } from 'components/organisms/GlobalFooter';
import { ClaimLookupResult } from 'components/organisms/ClaimLookupResult';
import { FormClaimLookup } from 'components/organisms/FormClaimLookup';
import { FlexAlignItemsEnum, FlexDirectionEnum } from 'enums/StyleTypes';
import { ClaimLookupContext, IClaimLookupTypeState } from 'context/ClaimLookup';

const ClaimLookup = ({ children }: IClaimLookupProps): ReactElement => {
    const { contextStateClaimLookup, updateContextStateClaimLookup } = React.useContext(
        ClaimLookupContext
    ) as IClaimLookupTypeState;
    /*
    console.info(
        '\n::::::::::::::::::::::::::::::::ClaimLookup:::::::::::::::::::::::::::::::::',
        '\n::contextStateClaimLookup::',
        contextStateClaimLookup,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );
    */

    return (
        <SCPageWrapper>
            <SCContentWrapper>
                <SCContentSectionResponsiveWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                >
                    <SCContentSectionWrapper>
                        {!contextStateClaimLookup.shouldDisplayResult &&
                            !contextStateClaimLookup.shouldDisplayNoResult && <FormClaimLookup />}
                        {(contextStateClaimLookup.shouldDisplayResult ||
                            contextStateClaimLookup.shouldDisplayNoResult) && (
                            <ClaimLookupResult data={contextStateClaimLookup}></ClaimLookupResult>
                        )}
                    </SCContentSectionWrapper>
                </SCContentSectionResponsiveWrapper>
            </SCContentWrapper>
            <SCFooterWrapper>
                <GlobalFooter />
            </SCFooterWrapper>
        </SCPageWrapper>
    );
};

export { ClaimLookup };
