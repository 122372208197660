import React, { ReactElement, useEffect, useState } from 'react';
import { SCDiv } from './styles';
import {
    $darkGray,
    SCContentSectionWrapper,
    SCFAQContentWrapper,
    SCSectionWrapper,
    SCSubHead
} from 'styles/global-styles';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { IFAQItems } from 'types/client-context';
import { Accordion } from 'components/molecules/Accordion';
import { Routes } from 'routes';
import { useHistory } from 'react-router-dom';
import { IFAQSummaryProps } from './types';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum } from 'enums/StyleTypes';

const FAQSummary = ({ distributorConfigurationData, groupName }: IFAQSummaryProps): ReactElement => {
    const [faqGeneral, setFAQGeneral] = useState<IFAQItems[]>();
    const history = useHistory();
    useEffect(() => {
        if (distributorConfigurationData) {
            const firstCollection =
                distributorConfigurationData.faq_collections &&
                distributorConfigurationData.faq_collections.filter((data) => {
                    return data.group_name === groupName;
                })[0];
            distributorConfigurationData.faq_collections &&
                firstCollection &&
                setFAQGeneral([
                    {
                        group_name: '',
                        group_label: '',
                        group_list:
                            firstCollection &&
                            firstCollection['group_list']
                                .filter((x) => x.summary_order > 0)
                                .sort((a, b) => a.summary_order - b.summary_order)
                    }
                ]);
        }
    }, [groupName, distributorConfigurationData]);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::FAQSummary:::::::::::::::::::::::::::::::::',
    //     '\n::$darkGray::',
    //     $darkGray,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCDiv>
            <SCContentSectionWrapper
                flexDirection={FlexDirectionEnum.COLUMN}
                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                flexGap={'36px'}
            >
                <SCFAQContentWrapper
                    flexDirection={FlexDirectionEnum.ROW}
                    flexAlignment={FlexAlignItemsEnum.CENTER}
                    flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                    flexGap={'36px'}
                >
                    <SCSubHead textColor={$darkGray}>Frequently Asked Questions</SCSubHead>
                    <TSButton
                        tsVariant={TSButtonVariantsEnum.UNDERLINE}
                        size={TSButtonSizeEnum.MEDIUM}
                        ariaLabel={'see all FAQs button'}
                        onClick={(): void => {
                            history.push(Routes.FAQS);
                        }}
                    >
                        See All FAQs
                    </TSButton>
                </SCFAQContentWrapper>
                {faqGeneral &&
                    faqGeneral.map((data: IFAQItems, index) => {
                        return (
                            <SCSectionWrapper key={`faq_item_${index}`}>
                                <Accordion data={data.group_list}></Accordion>
                            </SCSectionWrapper>
                        );
                    })}
            </SCContentSectionWrapper>
        </SCDiv>
    );
};

export { FAQSummary };
