import * as React from 'react';
import { IClaimFormJobLossTypeState, IClaimFormJobLossType } from './ClaimFormJobLossTypes';
import { IChildrenProps } from 'types/children';

export const ClaimFormJobLossContext = React.createContext<IClaimFormJobLossTypeState | null>(null);
const ClaimFormJobLossProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialState: IClaimFormJobLossType = {
        borrowerNameFirst: '',
        borrowerNameLast: '',
        borrowerMailingAddress: '',
        borrowerMailingCity: '',
        borrowerMailingState: undefined,
        borrowerMailingZip: '',
        borrowerEmail: '',
        borrowerPhone: '',
        borrowerSSN: '',
        borrowerDateOfBirth: '',
        borrowerComments: '',
        iuDateOfJobLoss: '',
        employerName: '',
        employerAddress: '',
        employerCity: '',
        employerState: undefined,
        employerZip: '',
        employerEmail: '',
        employerContactNumber: '',
        iuDateOfJobLossNotification: '',
        iuJobLossReason: '',
        iuDateLastDayWorked: '',
        iuIsLayoffTypicalAtThisTime: '',
        iuKnowledgeOfLayoffWhenHired: '',
        iuOccupationAtTimeOfSeparation: '',
        iuQualifyForStateUnemployment: '',
        iuRejectionStateBenefitsReason: '',
        iuSelfEmployed: '',
        eDeliveryConfirmation: '',
        iuFiledOnBehalfOf: undefined,
        submitterCompanyName: '',
        submitterNameFirst: '',
        submitterNameLast: '',
        submitterEmail: '',
        submitterPhone: ''
    };
    const [stateClaimFormJobLoss, setStateClaimFormJobLoss] = React.useState<IClaimFormJobLossType>(initialState);

    const saveStateClaimFormJobLoss = (formData: IClaimFormJobLossType): void => {
        const newFormData: IClaimFormJobLossType = {
            borrowerNameFirst: formData.borrowerNameFirst,
            borrowerNameLast: formData.borrowerNameLast,
            borrowerMailingAddress: formData.borrowerMailingAddress,
            borrowerMailingCity: formData.borrowerMailingCity,
            borrowerMailingState: formData.borrowerMailingState,
            borrowerMailingZip: formData.borrowerMailingZip,
            borrowerEmail: formData.borrowerEmail,
            borrowerPhone: formData.borrowerPhone,
            borrowerSSN: formData.borrowerSSN,
            borrowerDateOfBirth: formData.borrowerDateOfBirth,
            borrowerComments: formData.borrowerComments,
            employerContactNumber: formData.employerContactNumber,
            iuDateOfJobLoss: formData.iuDateOfJobLoss,
            employerName: formData.employerName,
            employerEmail: formData.employerEmail,
            employerAddress: formData.employerAddress,
            employerCity: formData.employerCity,
            employerState: formData.employerState,
            employerZip: formData.employerZip,
            iuDateOfJobLossNotification: formData.iuDateOfJobLossNotification,
            iuJobLossReason: formData.iuJobLossReason,
            iuDateLastDayWorked: formData.iuDateLastDayWorked,
            iuIsLayoffTypicalAtThisTime: formData.iuIsLayoffTypicalAtThisTime,
            iuKnowledgeOfLayoffWhenHired: formData.iuKnowledgeOfLayoffWhenHired,
            iuOccupationAtTimeOfSeparation: formData.iuOccupationAtTimeOfSeparation,
            iuQualifyForStateUnemployment: formData.iuQualifyForStateUnemployment,
            iuRejectionStateBenefitsReason: formData.iuRejectionStateBenefitsReason,
            iuSelfEmployed: formData.iuSelfEmployed,
            eDeliveryConfirmation: formData.eDeliveryConfirmation,
            iuFiledOnBehalfOf: formData.iuFiledOnBehalfOf,
            submitterCompanyName: formData.submitterCompanyName,
            submitterNameFirst: formData.submitterNameFirst,
            submitterNameLast: formData.submitterNameLast,
            submitterEmail: formData.submitterEmail,
            submitterPhone: formData.submitterPhone
        };
        setStateClaimFormJobLoss(newFormData);
    };
    const reinitialiseContextState = (): void => {
        setStateClaimFormJobLoss(initialState);
    };

    const stateObject: IClaimFormJobLossTypeState = {
        contextStateClaimFormJobLoss: stateClaimFormJobLoss,
        updateContextStateClaimFormJobLoss: saveStateClaimFormJobLoss,
        reinitialiseContextStateClaimFormJobLoss: reinitialiseContextState
    };
    return <ClaimFormJobLossContext.Provider value={stateObject}>{children}</ClaimFormJobLossContext.Provider>;
};

export default ClaimFormJobLossProvider;
