import styled from 'styled-components';

import { ChangeEvent, MouseEvent, ReactElement } from 'react';
import { DataExternal } from 'components/pages/ClaimsManagement';
import { Order } from 'components/molecules/TableHeadEnhanced';

export interface ITableHeadEnhancedExternalProps {
    children?: ReactElement | ReactElement[];
    numSelected: number;
    onRequestSort: (event: MouseEvent<unknown>, property: keyof DataExternal) => void;
    onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

export const TableHeaderTextWrapper = styled.th`
    padding: 0px 16px 0px 16px;
`;
