import { ReactElement } from 'react';

export interface INavigationProps {
    children?: ReactElement | ReactElement[];
    isAuthenticated?: boolean | undefined;
}

export enum NavigationSelected {
    HOME = 'home',
    CLAIM = 'claim',
    CLAIM_LOOKUP = 'claimLookup',
    FAQS = 'faqs',
    CONTACT = 'contact',
    PRIVACY = 'privacy',
    ADMIN = 'admin',
    NONE = ''
}
