import * as React from 'react';
import { IClaimLookupTypeState, IClaimLookupType } from './ClaimLookupTypes';
import { IChildrenProps } from 'types/children';

export const ClaimLookupContext = React.createContext<IClaimLookupTypeState | null>(null);
const ClaimLookupProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialState = {
        claimNumber: '',
        borrowerIdentifierKey: '',
        borrowerIdentifierTypeId: -1,
        borrowerPhoneNumber: '',
        peril: '',
        claimStatus: '',
        claimDecision: '',
        claimDecisionDate: '',
        claimDecisionReason: '',
        borrowerFirstName: '',
        borrowerLastName: '',
        distributorName: '',
        loanNumber: '',
        amountPaid: 0,
        filedDate: '',
        incurredDate: '',
        denialReason: '',
        shouldDisplayResult: false,
        shouldDisplayNoResult: false,
        claimBenefits: []
    };
    const [claimLookupResponseData, setClaimLookupResponseData] = React.useState<IClaimLookupType>(initialState);
    const saveClaimLookupResponseDataState = (claimLookupResponseData: IClaimLookupType): void => {
        const newclaimLookupResponseData: IClaimLookupType = {
            claimNumber: claimLookupResponseData.claimNumber,
            borrowerIdentifierKey: claimLookupResponseData.borrowerIdentifierKey,
            borrowerIdentifierTypeId: claimLookupResponseData.borrowerIdentifierTypeId,
            borrowerPhoneNumber: claimLookupResponseData.borrowerPhoneNumber,
            peril: claimLookupResponseData.peril,
            claimStatus: claimLookupResponseData.claimStatus,
            claimDecision: claimLookupResponseData.claimDecision,
            claimDecisionReason: claimLookupResponseData.claimDecisionReason,
            claimDecisionDate: claimLookupResponseData.claimDecisionDate,
            borrowerFirstName: claimLookupResponseData.borrowerFirstName,
            borrowerLastName: claimLookupResponseData.borrowerLastName,
            distributorName: claimLookupResponseData.distributorName,
            loanNumber: claimLookupResponseData.loanNumber,
            amountPaid: claimLookupResponseData.amountPaid,
            incurredDate: claimLookupResponseData.incurredDate,
            filedDate: claimLookupResponseData.filedDate,
            denialReason: claimLookupResponseData.denialReason,
            shouldDisplayResult: claimLookupResponseData.shouldDisplayResult,
            shouldDisplayNoResult: claimLookupResponseData.shouldDisplayNoResult,
            claimBenefits: claimLookupResponseData.claimBenefits
        };
        setClaimLookupResponseData(newclaimLookupResponseData);
    };

    const stateObject: IClaimLookupTypeState = {
        contextStateClaimLookup: claimLookupResponseData,
        updateContextStateClaimLookup: saveClaimLookupResponseDataState
    };
    return <ClaimLookupContext.Provider value={stateObject}>{children}</ClaimLookupContext.Provider>;
};

export default ClaimLookupProvider;
