import React, { ChangeEvent, ReactElement, useContext, useEffect, useState } from 'react';
import { SCRoot } from './styles';
import { IClaimBenefitProps } from './types';
import { FlexAlignItemsEnum, FormFieldWidthEnum } from 'enums/StyleTypes';
import { claimBenefitFormLabels, editFormLabels } from 'support/formLabels';
import {
    SCElementsWrapper,
    SCElementWrapper,
    SCErrorLabel,
    SCHRElement,
    SCTempDateFieldWrapper
} from 'styles/global-styles';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { TSTextField } from 'components/atoms/TSTextField';
import { TSAutocomplete } from 'components/atoms/TSAutocomplete';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { ClaimEditContext, IClaimEditType, IClaimEditTypeState } from 'context/ClaimEdit';
import { errorMapIU, formatCurrency, getNumberWithoutCommas } from 'utils/form/form';
import { MaxFieldLengths } from 'enums/MaxFieldLengths';
import { claimDecisions } from 'support/table';
import { ClaimAdjudicationConfigsContext, IClaimAdjudicationConfigsTypeState } from 'context/ClaimAdjudicationConfigs';
import { ClaimBenefitDecisionEnum } from 'enums';
import { objectKeyHasValue, stringHasValue } from 'utils/helpers/helpers';
import { IClaimsManagementClaimBenefitsData } from 'context/ClaimManagement';

const ClaimBenefit = ({ claimBenefit }: IClaimBenefitProps): ReactElement => {
    const { contextStateClaimEdit, updateContextStateClaimEdit, updateContextStateClaimEditBenefit } = useContext(
        ClaimEditContext
    ) as IClaimEditTypeState;
    const { contextStateClaimAdjudicationConfigs } = React.useContext(
        ClaimAdjudicationConfigsContext
    ) as IClaimAdjudicationConfigsTypeState;
    const [claimEditFormDataState, setClaimEditFormDataState] = React.useState<
        IClaimEditType | Record<string, unknown>
    >();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [amountPayable, setAmountPayable] = useState<any>();
    const [amountPayableDisplay, setAmountPayableDisplay] = useState<IClaimsManagementClaimBenefitsData | any>();
    const [additionalInformation, setAdditionalInformation] = useState<IClaimsManagementClaimBenefitsData | any>();
    const [amountPayableRequiredError, setAmountPayableRequiredError] = useState<string | undefined>(undefined);
    const [certificateEffectiveDate, setCertificateEffectiveDate] = useState<Dayjs | null>(null);
    const [externalSystemIdentifierRequiredError, setExternalSystemIdentifierRequiredError] = useState<
        string | undefined
    >(undefined);
    const [certificateEffectiveDateRequiredError, setCertificateEffectiveDateRequiredError] = useState<
        string | undefined
    >(undefined);
    const [certificateEffectiveDateError, setCertificateEffectiveDateError] = useState<string | undefined>(undefined);
    const [claimDecisionDate, setClaimDecisionDate] = useState<Dayjs | null>(null);
    const [claimDecisionDateError, setClaimDecisionDateError] = useState<string | undefined>(undefined);
    const [decisionEmailSentDateTimeUTC, setDecisionEmailSentDateTimeUTC] = useState<Dayjs | null>(null);
    const [claimDecisionDateRequiredError, setClaimDecisionDateRequiredError] = useState<string | undefined>(undefined);
    const [claimDecisionEmailSentDateRequiredError, setClaimDecisionEmailSentDateRequiredError] = useState<
        string | undefined
    >(undefined);
    const [claimDenialReasonRequiredError, setClaimDenialReasonRequiredError] = useState<string | undefined>(undefined);
    const [fastTrackedRequiredError, setFastTrackedRequiredError] = useState<string | undefined>(undefined);
    const [dateDecisionSentError, setDateDecisionSentError] = useState<string | undefined>(undefined);
    const [externalSystemId, setExternalSystemId] = useState<IClaimsManagementClaimBenefitsData | any>();
    const [visibleState, setVisibleState] = useState(false);
    const filteredArray: IClaimsManagementClaimBenefitsData[] = contextStateClaimEdit.ClaimBenefits.filter(
        (element) => {
            return element.IsDeleted !== true;
        }
    );
    const performRequiredValidationCheck = (): void => {
        claimBenefit?.DecisionStatus === ClaimBenefitDecisionEnum.APPROVED &&
        !objectKeyHasValue(claimBenefit, 'ExternalSystemIdentifier')
            ? setExternalSystemIdentifierRequiredError(errorMapIU['externalSystemIdentifierRequiredError'])
            : setExternalSystemIdentifierRequiredError(undefined);
        claimBenefit?.DecisionStatus === ClaimBenefitDecisionEnum.APPROVED &&
        (!objectKeyHasValue(claimBenefit, 'AmountPayable') || +claimBenefit?.AmountPayable < 0.005)
            ? setAmountPayableRequiredError(errorMapIU['amountPayableRequiredError'])
            : setAmountPayableRequiredError(undefined);
        claimBenefit?.DecisionStatus === ClaimBenefitDecisionEnum.APPROVED && !dayjs(certificateEffectiveDate).isValid()
            ? setCertificateEffectiveDateRequiredError(errorMapIU['dateCertificateEffectiveDateRequiredError'])
            : setCertificateEffectiveDateRequiredError(undefined);
        claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.OPEN && !dayjs(claimDecisionDate).isValid()
            ? setClaimDecisionDateRequiredError(errorMapIU['dateDecisionRequiredError'])
            : setClaimDecisionDateRequiredError(undefined);
        claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.OPEN && !dayjs(decisionEmailSentDateTimeUTC).isValid()
            ? setClaimDecisionEmailSentDateRequiredError(errorMapIU['decisionEmailSentDateRequiredError'])
            : setClaimDecisionEmailSentDateRequiredError(undefined);
        claimBenefit?.DecisionStatus === ClaimBenefitDecisionEnum.DENIED &&
        !objectKeyHasValue(claimBenefit, 'DecisionReason')
            ? setClaimDenialReasonRequiredError(errorMapIU['decisionDenialReasonRequiredError'])
            : setClaimDenialReasonRequiredError(undefined);
        claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.OPEN &&
        !objectKeyHasValue(claimBenefit, 'FastTracked')
            ? setFastTrackedRequiredError(errorMapIU['decisionFastTrackedRequiredError'])
            : setFastTrackedRequiredError(undefined);
        if (
            claimBenefit &&
            claimBenefit?.DecisionStatus === ClaimBenefitDecisionEnum.DENIED &&
            claimBenefit.AmountPayable !== '0'
        ) {
            claimBenefit.AmountPayable = '0';
            updateContextStateClaimEditBenefit(claimBenefit);
            updateContextStateClaimEdit({
                ...contextStateClaimEdit
            });
        }
        if (
            claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.DENIED &&
            stringHasValue(claimBenefit.DecisionReason)
        ) {
            claimBenefit.DecisionReason = '';
            updateContextStateClaimEditBenefit(claimBenefit);
            updateContextStateClaimEdit({
                ...contextStateClaimEdit
            });
        }
    };
    useEffect(() => {
        const contextStateClaimEditDeepCopy = structuredClone(contextStateClaimEdit);
        setClaimEditFormDataState(contextStateClaimEditDeepCopy);
        claimEditFormDataState &&
            JSON.stringify(claimEditFormDataState) !== JSON.stringify(contextStateClaimEdit) &&
            setIsFormDirty(true);
        performRequiredValidationCheck();
    }, [contextStateClaimEdit, isFormDirty]);
    useEffect(() => {
        claimBenefit.IsDeleted && setVisibleState(claimBenefit.IsDeleted);
        setAmountPayableDisplay({ value: formatCurrency(claimBenefit.AmountPayable) });
        setAdditionalInformation({ value: claimBenefit.DecisionAdditionalInfo });
        setExternalSystemId({ value: claimBenefit.ExternalSystemIdentifier as string });
    }, [claimBenefit]);
    useEffect(() => {
        performRequiredValidationCheck();
    }, [claimBenefit?.DecisionStatus]);
    useEffect(() => {
        setCertificateEffectiveDate(
            claimBenefit?.CertificateEffectiveDateTimeUTC ? dayjs(claimBenefit?.CertificateEffectiveDateTimeUTC) : null
        );
        setClaimDecisionDate(claimBenefit?.DecisionDateTimeUTC ? dayjs(claimBenefit?.DecisionDateTimeUTC) : null);
        setDecisionEmailSentDateTimeUTC(
            claimBenefit?.DecisionEmailSentDateTimeUTC ? dayjs(claimBenefit?.DecisionEmailSentDateTimeUTC) : null
        );
    }, []);
    /*
    console.info(
        '\n::::::::::::::::::::::::::::::::ClaimBenefit:::::::::::::::::::::::::::::::::',
        '\n::claimBenefit.ClaimBenefitId::',
        claimBenefit.ClaimBenefitId,
        '\n::claimBenefit::',
        claimBenefit,
        '\n::claimEditFormDataState::',
        claimEditFormDataState,
        '\n::claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.OPEN::',
        claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.OPEN,
        '\n::amountPayableDisplay::',
        amountPayableDisplay,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );
    */

    return (
        <SCRoot visibleState={visibleState}>
            <SCElementsWrapper>
                <SCElementWrapper
                    widthAmount={FormFieldWidthEnum.QUADRUPLE}
                    flexAlignment={FlexAlignItemsEnum.FLEX_END}
                >
                    <TSButton
                        ariaLabel="Remove Benefit button"
                        tsVariant={TSButtonVariantsEnum.MINUS}
                        size={TSButtonSizeEnum.MEDIUM}
                        onClick={(): void => {
                            claimBenefit.IsDeleted = true;
                            updateContextStateClaimEditBenefit(claimBenefit);
                        }}
                        disabled={filteredArray.length <= 1}
                    >
                        Remove Benefit
                    </TSButton>
                </SCElementWrapper>
            </SCElementsWrapper>
            <SCElementsWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <TSTextField
                        id={'benefitId'}
                        ariaLabel={claimBenefitFormLabels.benefitId}
                        label={claimBenefitFormLabels.benefitId}
                        value={claimBenefit?.ClaimBenefitId}
                        readOnly={true}
                        placeholder={claimBenefit?.ClaimBenefitId}
                    ></TSTextField>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <TSTextField
                        id={'externalSystemIdentifier'}
                        label={claimBenefitFormLabels.externalSystemIdentifier}
                        ariaLabel={claimBenefitFormLabels.externalSystemIdentifier}
                        value={externalSystemId?.value}
                        onChange={(fieldEvent): void => {
                            setExternalSystemId({
                                id: fieldEvent.currentTarget.id,
                                value: fieldEvent.currentTarget.value
                            });
                            fieldEvent.currentTarget.value && setIsFormDirty(true);
                            claimBenefit?.DecisionStatus === ClaimBenefitDecisionEnum.APPROVED &&
                            !stringHasValue(fieldEvent.currentTarget.value)
                                ? setExternalSystemIdentifierRequiredError(
                                      errorMapIU['externalSystemIdentifierRequiredError']
                                  )
                                : setExternalSystemIdentifierRequiredError(undefined);
                        }}
                        onBlur={(): void => {
                            claimBenefit.ExternalSystemIdentifier = externalSystemId.value
                                ? externalSystemId.value
                                : '';
                            updateContextStateClaimEditBenefit(claimBenefit);
                            updateContextStateClaimEdit({
                                ...contextStateClaimEdit,
                                isDirty: true
                            });
                            claimBenefit?.DecisionStatus === ClaimBenefitDecisionEnum.APPROVED &&
                            !objectKeyHasValue(claimBenefit, 'ExternalSystemIdentifier')
                                ? setExternalSystemIdentifierRequiredError(
                                      errorMapIU['externalSystemIdentifierRequiredError']
                                  )
                                : setExternalSystemIdentifierRequiredError(undefined);
                        }}
                        placeholder={editFormLabels.loanNumber}
                        maxLength={MaxFieldLengths.GENERAL_MAX}
                    ></TSTextField>
                    <SCErrorLabel>{externalSystemIdentifierRequiredError}</SCErrorLabel>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <SCTempDateFieldWrapper>
                        <DatePicker
                            label={claimBenefitFormLabels.dateCertificateEffective}
                            className="react-datepicker-filter"
                            views={['month', 'day', 'year']}
                            slotProps={{ field: { clearable: true } }}
                            defaultValue={dayjs('invalid date')}
                            value={certificateEffectiveDate ? dayjs(certificateEffectiveDate) : null}
                            onChange={(value, context): void => {
                                setCertificateEffectiveDate(value);
                                claimBenefit.CertificateEffectiveDateTimeUTC = dayjs(value).isValid()
                                    ? dayjs(value).toISOString()
                                    : '';
                                updateContextStateClaimEditBenefit(claimBenefit);
                                updateContextStateClaimEdit({
                                    ...contextStateClaimEdit,
                                    isDirty: true
                                });
                                claimBenefit?.DecisionStatus === ClaimBenefitDecisionEnum.APPROVED &&
                                !dayjs(certificateEffectiveDate).isValid()
                                    ? setCertificateEffectiveDateRequiredError(
                                          errorMapIU['dateCertificateEffectiveDateRequiredError']
                                      )
                                    : setCertificateEffectiveDateRequiredError(undefined);
                            }}
                            onAccept={(value): void => {
                                setCertificateEffectiveDate(value);
                                claimBenefit.CertificateEffectiveDateTimeUTC = dayjs(value).isValid()
                                    ? dayjs(value).toISOString()
                                    : '';
                                updateContextStateClaimEditBenefit(claimBenefit);
                                updateContextStateClaimEdit({
                                    ...contextStateClaimEdit,
                                    isDirty: true
                                });
                                claimBenefit?.DecisionStatus === ClaimBenefitDecisionEnum.APPROVED &&
                                !dayjs(certificateEffectiveDate).isValid()
                                    ? setCertificateEffectiveDateRequiredError(
                                          errorMapIU['dateCertificateEffectiveDateRequiredError']
                                      )
                                    : setCertificateEffectiveDateRequiredError(undefined);
                            }}
                            onError={(value): void => {
                                if (value === 'maxDate') {
                                    setCertificateEffectiveDateError(errorMapIU['dateMaximumError']);
                                } else if (value === 'minDate') {
                                    setCertificateEffectiveDateError(errorMapIU['dateMinimumError']);
                                } else if (value === 'invalidDate') {
                                    setCertificateEffectiveDateError(errorMapIU['dateError']);
                                } else {
                                    setCertificateEffectiveDateError(undefined);
                                    claimBenefit?.DecisionStatus === ClaimBenefitDecisionEnum.APPROVED &&
                                    !dayjs(certificateEffectiveDate).isValid()
                                        ? setCertificateEffectiveDateRequiredError(
                                              errorMapIU['dateCertificateEffectiveDateRequiredError']
                                          )
                                        : setCertificateEffectiveDateRequiredError(undefined);
                                }
                            }}
                        />
                    </SCTempDateFieldWrapper>
                    <SCErrorLabel>{certificateEffectiveDateError}</SCErrorLabel>
                    <SCErrorLabel>{certificateEffectiveDateRequiredError}</SCErrorLabel>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <TSAutocomplete
                        id={'benefitDecision'}
                        label={claimBenefitFormLabels.benefitDecision}
                        ariaLabel={claimBenefitFormLabels.benefitDecision}
                        options={claimDecisions}
                        value={claimBenefit?.DecisionStatus}
                        onChange={(event, value): void => {
                            claimBenefit.DecisionStatus = value ? value : ClaimBenefitDecisionEnum.OPEN;
                            updateContextStateClaimEditBenefit(claimBenefit);
                            updateContextStateClaimEdit({
                                ...contextStateClaimEdit,
                                isDirty: true
                            });
                        }}
                    ></TSAutocomplete>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <TSTextField
                        id={'amountPayable'}
                        label={claimBenefitFormLabels.amountPayable}
                        ariaLabel={claimBenefitFormLabels.amountPayable}
                        value={amountPayableDisplay?.value}
                        isDisabled={claimBenefit?.DecisionStatus === ClaimBenefitDecisionEnum.DENIED}
                        onChange={(fieldEvent: ChangeEvent<HTMLInputElement>): void => {
                            setAmountPayable({
                                id: fieldEvent.currentTarget.id,
                                value: fieldEvent.currentTarget.value
                            });
                            setAmountPayableDisplay({
                                id: amountPayableDisplay?.id,
                                value: fieldEvent.currentTarget.value
                            });
                            claimBenefit?.DecisionStatus === ClaimBenefitDecisionEnum.APPROVED &&
                            (!fieldEvent.currentTarget.value || +formatCurrency(fieldEvent.currentTarget.value) < 0.005)
                                ? setAmountPayableRequiredError(errorMapIU['amountPayableRequiredError'])
                                : setAmountPayableRequiredError(undefined);
                        }}
                        onBlur={(value): void => {
                            claimBenefit.AmountPayable = amountPayable?.value
                                ? getNumberWithoutCommas(formatCurrency(amountPayable?.value))
                                : '0';
                            updateContextStateClaimEditBenefit(claimBenefit);
                            updateContextStateClaimEdit({
                                ...contextStateClaimEdit,
                                isDirty: true
                            });
                            setAmountPayableDisplay({
                                id: amountPayableDisplay?.id,
                                value: formatCurrency(amountPayable?.value)
                            });
                        }}
                        placeholder={claimBenefitFormLabels.amountPayable}
                        startAdornment={<span>$</span>}
                        maxLength={MaxFieldLengths.GENERAL_MAX}
                    ></TSTextField>
                    <SCErrorLabel>{amountPayableRequiredError}</SCErrorLabel>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <SCTempDateFieldWrapper>
                        <DatePicker
                            label={claimBenefitFormLabels.claimDecisionDate}
                            className="react-datepicker-filter"
                            views={['month', 'day', 'year']}
                            slotProps={{ field: { clearable: true } }}
                            defaultValue={dayjs('invalid date')}
                            value={claimDecisionDate ? dayjs(claimDecisionDate) : null}
                            onChange={(value, context): void => {
                                setClaimDecisionDate(value);
                                claimBenefit.DecisionDateTimeUTC = dayjs(value).isValid()
                                    ? dayjs(value).toISOString()
                                    : '';
                                updateContextStateClaimEditBenefit(claimBenefit);
                                updateContextStateClaimEdit({
                                    ...contextStateClaimEdit,
                                    isDirty: true
                                });
                                claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.OPEN &&
                                !dayjs(claimDecisionDate).isValid()
                                    ? setClaimDecisionDateRequiredError(errorMapIU['dateDecisionRequiredError'])
                                    : setClaimDecisionDateRequiredError(undefined);
                            }}
                            onAccept={(value): void => {
                                setClaimDecisionDate(value);
                                if (dayjs(value).isValid()) {
                                    claimBenefit.DecisionDateTimeUTC = dayjs(value).toISOString();
                                    updateContextStateClaimEditBenefit(claimBenefit);
                                    updateContextStateClaimEdit({
                                        ...contextStateClaimEdit,
                                        isDirty: true
                                    });
                                }
                                claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.OPEN &&
                                !dayjs(claimDecisionDate).isValid()
                                    ? setClaimDecisionDateRequiredError(errorMapIU['dateDecisionRequiredError'])
                                    : setClaimDecisionDateRequiredError(undefined);
                            }}
                            onError={(value): void => {
                                if (value === 'maxDate') {
                                    setClaimDecisionDateError(errorMapIU['dateMaximumError']);
                                } else if (value === 'minDate') {
                                    setClaimDecisionDateError(errorMapIU['dateMinimumError']);
                                } else if (value === 'invalidDate') {
                                    setClaimDecisionDateError(errorMapIU['dateError']);
                                } else {
                                    setClaimDecisionDateError(undefined);
                                    claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.OPEN &&
                                    !dayjs(claimDecisionDate).isValid()
                                        ? setClaimDecisionDateRequiredError(errorMapIU['dateDecisionRequiredError'])
                                        : setClaimDecisionDateRequiredError(undefined);
                                }
                            }}
                        />
                    </SCTempDateFieldWrapper>
                    <SCErrorLabel>{claimDecisionDateError}</SCErrorLabel>
                    <SCErrorLabel>{claimDecisionDateRequiredError}</SCErrorLabel>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.TRIPLE}>
                    <TSAutocomplete
                        id={'decisionReason'}
                        label={claimBenefitFormLabels.decisionReason}
                        ariaLabel={claimBenefitFormLabels.decisionReason}
                        options={contextStateClaimAdjudicationConfigs?.decisionReasons}
                        value={claimBenefit.DecisionReason}
                        disabled={claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.DENIED}
                        onChange={(event, value): void => {
                            claimBenefit.DecisionReason = value ? value : '';
                            updateContextStateClaimEditBenefit(claimBenefit);
                            updateContextStateClaimEdit({
                                ...contextStateClaimEdit,
                                isDirty: true
                            });
                            claimBenefit?.DecisionStatus === ClaimBenefitDecisionEnum.DENIED && !value
                                ? setClaimDenialReasonRequiredError(errorMapIU['decisionDenialReasonRequiredError'])
                                : setClaimDenialReasonRequiredError(undefined);
                        }}
                    ></TSAutocomplete>
                    <SCErrorLabel>{claimDenialReasonRequiredError}</SCErrorLabel>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <SCTempDateFieldWrapper>
                        <DatePicker
                            label={claimBenefitFormLabels.dateDecisionSent}
                            className="react-datepicker-filter"
                            views={['month', 'day', 'year']}
                            defaultValue={dayjs('invalid date')}
                            slotProps={{ field: { clearable: true } }}
                            value={decisionEmailSentDateTimeUTC ? dayjs(decisionEmailSentDateTimeUTC) : null}
                            onChange={(value): void => {
                                setDecisionEmailSentDateTimeUTC(value);
                                claimBenefit.DecisionEmailSentDateTimeUTC = dayjs(value).isValid()
                                    ? dayjs(value).toISOString()
                                    : '';
                                updateContextStateClaimEditBenefit(claimBenefit);
                                updateContextStateClaimEdit({
                                    ...contextStateClaimEdit,
                                    isDirty: true
                                });
                                claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.OPEN &&
                                !dayjs(decisionEmailSentDateTimeUTC).isValid()
                                    ? setClaimDecisionEmailSentDateRequiredError(
                                          errorMapIU['decisionEmailSentDateRequiredError']
                                      )
                                    : setClaimDecisionEmailSentDateRequiredError(undefined);
                            }}
                            onAccept={(value): void => {
                                setDecisionEmailSentDateTimeUTC(value);
                                claimBenefit.DecisionEmailSentDateTimeUTC = dayjs(value).isValid()
                                    ? dayjs(value).toISOString()
                                    : '';
                                updateContextStateClaimEditBenefit(claimBenefit);
                                updateContextStateClaimEdit({
                                    ...contextStateClaimEdit,
                                    isDirty: true
                                });
                                claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.OPEN &&
                                !dayjs(decisionEmailSentDateTimeUTC).isValid()
                                    ? setClaimDecisionEmailSentDateRequiredError(
                                          errorMapIU['decisionEmailSentDateRequiredError']
                                      )
                                    : setClaimDecisionEmailSentDateRequiredError(undefined);
                            }}
                            onError={(value): void => {
                                if (value === 'maxDate') {
                                    setDateDecisionSentError(errorMapIU['dateMaximumError']);
                                } else if (value === 'minDate') {
                                    setDateDecisionSentError(errorMapIU['dateMinimumError']);
                                } else if (value === 'invalidDate') {
                                    setDateDecisionSentError(errorMapIU['dateError']);
                                } else {
                                    setDateDecisionSentError(undefined);
                                    claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.OPEN &&
                                    !dayjs(decisionEmailSentDateTimeUTC).isValid()
                                        ? setClaimDecisionEmailSentDateRequiredError(
                                              errorMapIU['decisionEmailSentDateRequiredError']
                                          )
                                        : setClaimDecisionEmailSentDateRequiredError(undefined);
                                }
                            }}
                        />
                    </SCTempDateFieldWrapper>
                    <SCErrorLabel>{dateDecisionSentError}</SCErrorLabel>
                    <SCErrorLabel>{claimDecisionEmailSentDateRequiredError}</SCErrorLabel>
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.QUADRUPLE}>
                    <TSTextField
                        id={'additionalInfo'}
                        label={claimBenefitFormLabels.decisionAdditionalInformation}
                        placeholder={claimBenefitFormLabels.decisionAdditionalInformation}
                        ariaLabel={claimBenefitFormLabels.decisionAdditionalInformation}
                        value={additionalInformation?.value}
                        defaultValue={additionalInformation?.value}
                        onChange={(fieldEvent: ChangeEvent<HTMLInputElement>): void => {
                            setAdditionalInformation({
                                id: fieldEvent.currentTarget.id,
                                value: fieldEvent.currentTarget.value
                            });
                        }}
                        onBlur={(): void => {
                            claimBenefit.DecisionAdditionalInfo = additionalInformation.value
                                ? additionalInformation.value
                                : '';
                            updateContextStateClaimEditBenefit(claimBenefit);
                            updateContextStateClaimEdit({
                                ...contextStateClaimEdit,
                                isDirty: true
                            });
                        }}
                        multiline={true}
                        minRows={5}
                        maxLength={MaxFieldLengths.GENERAL_MAX}
                    />
                </SCElementWrapper>
                <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                    <TSAutocomplete
                        id={'fastTracked'}
                        label={editFormLabels.fastTracked}
                        ariaLabel={editFormLabels.fastTracked}
                        options={[true, false]}
                        value={claimBenefit?.FastTracked}
                        getOptionLabel={(fastTrackedBool): string =>
                            fastTrackedBool ? 'Yes' : !fastTrackedBool ? 'No' : ''
                        }
                        onChange={(event, value): void => {
                            claimBenefit.FastTracked = value;
                            updateContextStateClaimEditBenefit(claimBenefit);
                            updateContextStateClaimEdit({
                                ...contextStateClaimEdit,
                                isDirty: true
                            });
                            claimBenefit?.DecisionStatus !== ClaimBenefitDecisionEnum.OPEN && value === null
                                ? setFastTrackedRequiredError(errorMapIU['decisionFastTrackedRequiredError'])
                                : setFastTrackedRequiredError(undefined);
                        }}
                    ></TSAutocomplete>
                    <SCErrorLabel>{fastTrackedRequiredError}</SCErrorLabel>
                </SCElementWrapper>
            </SCElementsWrapper>
            <SCHRElement />
        </SCRoot>
    );
};

export { ClaimBenefit };
