import * as React from 'react';
import { IClaimFormDisabilityType, IClaimFormDisabilityTypeState } from './ClaimFormDisabilityTypes';
import { IChildrenProps } from 'types/children';

export const ClaimFormDisabilityContext = React.createContext<IClaimFormDisabilityTypeState | null>(null);
const ClaimFormDisabilityProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialState: IClaimFormDisabilityType = {
        borrowerNameFirst: '',
        borrowerNameLast: '',
        borrowerMailingAddress: '',
        borrowerMailingCity: '',
        borrowerMailingState: undefined,
        borrowerMailingZip: '',
        borrowerEmail: '',
        borrowerPhone: '',
        borrowerSSN: '',
        borrowerDateOfBirth: '',
        borrowerComments: '',
        employerName: '',
        employerEmail: '',
        employerContactNumber: '',
        employerAddress: '',
        employerCity: '',
        employerState: undefined,
        employerZip: '',
        disabilityHealthcareName: '',
        disabilityHealthcareHospitalName: '',
        disabilityHealthcareContactNumber: '',
        disabilityDateOfIncident: '',
        disabilityExplanationOf: '',
        disabilityTypeOfSurgery: '',
        disabilityDateLastWorked: '',
        disabilityKnowWhenExpectedWorking: '',
        disabilityDateExpectedWorking: '',
        disabilityHealthcareAddress: '',
        disabilityHealthcareCity: '',
        disabilityHealthcareState: undefined,
        disabilityHealthcareZip: '',
        disabilityFirstPhysicianDate: '',
        disabilityJobDuties: '',
        disabilityFiledOnBehalfOf: undefined,
        submitterCompanyName: '',
        submitterNameFirst: '',
        submitterNameLast: '',
        submitterEmail: '',
        submitterPhone: '',
        eDeliveryConfirmation: ''
    };
    const [stateClaimFormDisability, setStateClaimFormDisability] =
        React.useState<IClaimFormDisabilityType>(initialState);

    const saveStateClaimFormDisability = (formData: IClaimFormDisabilityType): void => {
        const newFormData: IClaimFormDisabilityType = {
            borrowerNameFirst: formData.borrowerNameFirst,
            borrowerNameLast: formData.borrowerNameLast,
            borrowerMailingAddress: formData.borrowerMailingAddress,
            borrowerMailingCity: formData.borrowerMailingCity,
            borrowerMailingState: formData.borrowerMailingState,
            borrowerMailingZip: formData.borrowerMailingZip,
            borrowerEmail: formData.borrowerEmail,
            borrowerPhone: formData.borrowerPhone,
            borrowerSSN: formData.borrowerSSN,
            borrowerDateOfBirth: formData.borrowerDateOfBirth,
            borrowerComments: formData.borrowerComments,
            disabilityDateOfIncident: formData.disabilityDateOfIncident,
            disabilityExplanationOf: formData.disabilityExplanationOf,
            disabilityTypeOfSurgery: formData.disabilityTypeOfSurgery,
            disabilityKnowWhenExpectedWorking: formData.disabilityKnowWhenExpectedWorking,
            disabilityDateLastWorked: formData.disabilityDateLastWorked,
            disabilityDateExpectedWorking: formData.disabilityDateExpectedWorking,
            disabilityFiledOnBehalfOf: formData.disabilityFiledOnBehalfOf,
            employerName: formData.employerName,
            employerEmail: formData.employerEmail,
            employerContactNumber: formData.employerContactNumber,
            employerAddress: formData.employerAddress,
            employerCity: formData.employerCity,
            employerState: formData.employerState,
            employerZip: formData.employerZip,
            disabilityHealthcareName: formData.disabilityHealthcareName,
            disabilityHealthcareHospitalName: formData.disabilityHealthcareHospitalName,
            disabilityHealthcareContactNumber: formData.disabilityHealthcareContactNumber,
            disabilityHealthcareAddress: formData.disabilityHealthcareAddress,
            disabilityHealthcareCity: formData.disabilityHealthcareCity,
            disabilityHealthcareState: formData.disabilityHealthcareState,
            disabilityHealthcareZip: formData.disabilityHealthcareZip,
            disabilityFirstPhysicianDate: formData.disabilityFirstPhysicianDate,
            disabilityJobDuties: formData.disabilityJobDuties,
            eDeliveryConfirmation: formData.eDeliveryConfirmation,
            submitterCompanyName: formData.submitterCompanyName,
            submitterNameFirst: formData.submitterNameFirst,
            submitterNameLast: formData.submitterNameLast,
            submitterEmail: formData.submitterEmail,
            submitterPhone: formData.submitterPhone
        };
        setStateClaimFormDisability(newFormData);
    };
    const reinitialiseFormDisabilityState = (): void => {
        setStateClaimFormDisability(initialState);
    };

    const stateObject: IClaimFormDisabilityTypeState = {
        contextStateClaimFormDisability: stateClaimFormDisability,
        updateContextStateClaimFormDisability: saveStateClaimFormDisability,
        reinitialiseStateClaimFormDisability: reinitialiseFormDisabilityState
    };
    return <ClaimFormDisabilityContext.Provider value={stateObject}>{children}</ClaimFormDisabilityContext.Provider>;
};

export default ClaimFormDisabilityProvider;
