import * as React from 'react';
import { IClaimProgressJobLossTypeState, IClaimProgressJobLossType } from './ClaimProgressJobLossTypes';
import { IChildrenProps } from 'types/children';
import { TimelineState } from 'enums';

export const ClaimProgressJobLossContext = React.createContext<IClaimProgressJobLossTypeState | null>(null);
const ClaimProgressJobLossProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialState = [
        {
            id: 'id-1',
            label: 'Personal Information',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-2',
            label: 'Claim details',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-3',
            label: 'Employer Details',
            status: TimelineState.INCOMPLETE
        },
        {
            id: 'id-4',
            label: 'Comments (optional)',
            status: TimelineState.INCOMPLETE
        }
    ];
    const [stateClaimProgressJobLoss, setStateClaimProgressJobLoss] =
        React.useState<IClaimProgressJobLossType[]>(initialState);
    const [formJoblossProgressCompletionStatus, setFormProgressCompletionStatus] = React.useState(false);
    const updateFormJobLossProgressState = (id: string, status: TimelineState): void => {
        stateClaimProgressJobLoss.filter((formData) => {
            if (formData.id === id) {
                formData.status = status;
                setStateClaimProgressJobLoss([...stateClaimProgressJobLoss]);
            }
        });
    };
    const resetFormJobLossProgressState = (): void => {
        setStateClaimProgressJobLoss(initialState);
    };
    const updateFormJobLossProgressCompletionStatus = (status: boolean): void => {
        setFormProgressCompletionStatus(status);
    };
    const updateAllFormJobLossProgressStatusComplete = (): void => {
        stateClaimProgressJobLoss.map((obj) => {
            obj.status = TimelineState.COMPLETE;
            setStateClaimProgressJobLoss([...stateClaimProgressJobLoss]);
        });
    };
    const stateObject: IClaimProgressJobLossTypeState = {
        contextStateClaimProgressJobLoss: stateClaimProgressJobLoss,
        updateContextStateClaimProgressJobLoss: updateFormJobLossProgressState,
        resetContextStateClaimProgressJobLoss: resetFormJobLossProgressState,
        claimProgressJobLossCompletionStatus: formJoblossProgressCompletionStatus,
        updateClaimProgressJobLossCompletionStatus: updateFormJobLossProgressCompletionStatus,
        updateAllClaimProgressJobLossCompletionStatus: updateAllFormJobLossProgressStatusComplete
    };
    return <ClaimProgressJobLossContext.Provider value={stateObject}>{children}</ClaimProgressJobLossContext.Provider>;
};

export default ClaimProgressJobLossProvider;
