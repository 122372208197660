import { useEffect, useRef, useState } from 'react';

const UseTimer = (
    callBackMethod,
    timeout
): {
    isRunning: boolean;
    toggleTimer: () => void;
    startTimer: () => void;
    stopTimer: () => void;
} => {
    const intervalId = useRef<NodeJS.Timer>();
    const [isRunning, setIsRunning] = useState(false);
    useEffect(() => {
        return clearInterval(intervalId.current);
    }, []);
    useEffect(() => {
        if (isRunning) {
            intervalId.current = setInterval(callBackMethod, timeout);
        } else {
            clearInterval(intervalId.current);
            intervalId.current = undefined;
        }
    }, [isRunning]);
    const toggleTimer = (): void => {
        setIsRunning((isRunning) => !isRunning);
    };
    const startTimer = (): void => {
        setIsRunning(true);
    };
    const stopTimer = (): void => {
        setIsRunning(false);
    };
    /*
    console.info(
        '\n::::::::::::::::::::::::::::::::Timer::UseTimer:::::::::::::::::::::::::::::::',
        '\n::isRunning::',
        isRunning,
        '\n::callBackMethod::',
        callBackMethod,
        '\n::intervalId.current::',
        intervalId.current,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );
    */
    return { isRunning, startTimer, stopTimer, toggleTimer };
};

export default UseTimer;
