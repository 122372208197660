import React, { ReactElement, useEffect } from 'react';
import { ClaimSearchService } from 'api/ClaimSearch';
import { IClaimsManagementProps } from './types';
import {
    SCBodyLarge,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCContentWrapper,
    SCFooterWrapper,
    SCPageWrapper,
    SCSpinnerWrapper,
    SCSubHead
} from 'styles/global-styles';
import { TableEnhanced } from 'components/molecules/TableEnhanced';
import { AuthenticationGroupRolesEnum } from 'enums/AuthenticationEnums';
import { TableEnhancedExternal } from 'components/molecules/TableEnhancedExternal';
import { GlobalFooter } from 'components/organisms/GlobalFooter';
import { FlexAlignItemsEnum, FlexDirectionEnum } from 'enums/StyleTypes';
import { CircularProgress } from '@mui/material';
import { ClaimAdjudicationConfigsContext, IClaimAdjudicationConfigsTypeState } from 'context/ClaimAdjudicationConfigs';
import { ClaimAdjudicationConfigsService } from 'api/ClaimAdjudicationConfigsService';
import { IClaimAdjudicationConfigsApiResponse } from 'types/claim-adjudication-config';
import { ClaimManagementContext, IClaimManagementType, IClaimManagementTypeState } from 'context/ClaimManagement';
import { AuthenticationStateContext, IAuthenticationStateTypeState } from 'context/AuthenticationState';

const ClaimsManagement = ({ children }: IClaimsManagementProps): ReactElement => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { contextStateAuthenticationState, updateContextStateAuthenticationState } = React.useContext(
        AuthenticationStateContext
    ) as IAuthenticationStateTypeState;
    const {
        contextStateClaimManagement,
        initializeStateClaimManagement,
        updateStateClaimManagement,
        contextStateClaimsManagementTable,
        initializeStateClaimsManagementTable,
        updateContextStateClaimManagementTable
    } = React.useContext(ClaimManagementContext) as IClaimManagementTypeState;
    const { updateContextStateClaimAdjudicationConfigs, resetContextStateClaimAdjudicationConfigs } = React.useContext(
        ClaimAdjudicationConfigsContext
    ) as IClaimAdjudicationConfigsTypeState;
    const getClaimAdjudicationConfigs = async (): Promise<void> => {
        if (contextStateAuthenticationState) {
            const clientConfigsApiResponse =
                (await ClaimAdjudicationConfigsService()) as IClaimAdjudicationConfigsApiResponse;
            if (clientConfigsApiResponse?.payload) {
                updateContextStateClaimAdjudicationConfigs(clientConfigsApiResponse.payload);
            }
        } else {
            resetContextStateClaimAdjudicationConfigs();
        }
    };
    useEffect(() => {
        getClaimAdjudicationConfigs();
    }, [contextStateAuthenticationState]);
    useEffect(() => {
        const getSearchData = (): void => {
            setIsLoading(true);
            ClaimSearchService.getSearchWithResponse()
                .then((res) => {
                    if (res.data) {
                        const data = res.data as IClaimManagementType[];
                        initializeStateClaimsManagementTable(data);
                        initializeStateClaimManagement(data);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    console.error(
                        '\n::::::::::::::::::::::::::::::::ClaimsManagement::getSearchData:::::::::::::::::::::::::::::::',
                        '\n::error::',
                        error,
                        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
                    );
                    setIsLoading(false);
                });
        };
        getSearchData();
    }, []);
    /*
    console.info(
        '\n::::::::::::::::::::::::::::::::ClaimsManagement:::::::::::::::::::::::::::::::::',
        '\n::AuthenticationGroupRolesEnum::',
        AuthenticationGroupRolesEnum,
        '\n::contextStateAuthenticationState::',
        contextStateAuthenticationState,
        '\n::contextStateClaimManagement::',
        contextStateClaimManagement,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );
    */
    return (
        <>
            {!isLoading && (
                <SCPageWrapper>
                    <SCContentWrapper>
                        <SCContentSectionResponsiveWrapper
                            flexDirection={FlexDirectionEnum.COLUMN}
                            flexAlignment={FlexAlignItemsEnum.FLEX_START}
                            flexGap={'24px'}
                        >
                            <SCSubHead>Claims Reporting</SCSubHead>
                            <SCBodyLarge>
                                You can use this page to see a list of all of the Payment Guard claims for your given
                                search/sort criteria. You can then download a CSV of the results at the bottom of the
                                table.
                            </SCBodyLarge>
                            <SCContentSectionWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.CENTER}
                                flexGap={'24px'}
                            >
                                {(contextStateAuthenticationState.groups?.includes(
                                    AuthenticationGroupRolesEnum.ADMIN_ACCESS_WRITE
                                ) && <TableEnhanced />) ||
                                    (contextStateAuthenticationState.groups?.includes(
                                        AuthenticationGroupRolesEnum.ADMIN_ACCESS_READ
                                    ) && <TableEnhancedExternal />)}
                            </SCContentSectionWrapper>
                        </SCContentSectionResponsiveWrapper>
                    </SCContentWrapper>
                    <SCFooterWrapper>
                        <GlobalFooter />
                    </SCFooterWrapper>
                </SCPageWrapper>
            )}
            {isLoading && (
                <SCSpinnerWrapper>
                    <CircularProgress
                        sx={{
                            color: (theme): string => {
                                return theme.palette.common.black;
                            },
                            animationDuration: '1550ms'
                        }}
                        size={100}
                        thickness={4}
                    />
                </SCSpinnerWrapper>
            )}
        </>
    );
};

export { ClaimsManagement };
