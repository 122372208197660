import React, { ReactElement } from 'react';
// import { useRouteError } from 'react-router-dom';
import { SCDiv } from './styles';
// import { IError, IErrorMessage, IErrorProps, IErrorResponse } from './types';

const Errors = (error): ReactElement => {
    // const error: any = useRouteError();
    console.error(
        '\n::::::::::::::::::::::::::::::::Error:::::::::::::::::::::::::::::::::',
        '\n::error::',
        error,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );

    return (
        <SCDiv>
            <div id="error-page">
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>{<i>{error.statusText || error.message}</i>}</p>
            </div>
        </SCDiv>
    );
};

export { Errors };
