import * as React from 'react';
import { IAuthenticationStateTypeState, IAuthenticationStateType } from './AuthenticationStateTypes';
import { IChildrenProps } from 'types/children';
import { ClaimStateEnum } from 'enums';

export const AuthenticationStateContext = React.createContext<IAuthenticationStateTypeState | null>(null);
const AuthenticationStateProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialState = {
        reviewState: ClaimStateEnum.ACTIVE,
        authenticatedRedirectPath: ''
    };
    const [stateAuthenticationState, setStateAuthenticationState] =
        React.useState<IAuthenticationStateType>(initialState);
    const updateAuthenticationType = (contextStateAuthenticationState: IAuthenticationStateType): void => {
        const newAuthenticationData: IAuthenticationStateType = {
            roleLevel: contextStateAuthenticationState.roleLevel,
            reviewState: contextStateAuthenticationState.reviewState,
            isAuthenticated: contextStateAuthenticationState.isAuthenticated,
            authenticatedRedirectPath: contextStateAuthenticationState.authenticatedRedirectPath,
            userName: contextStateAuthenticationState.userName,
            distributorKey: contextStateAuthenticationState.distributorKey,
            loanServicerKey: contextStateAuthenticationState.loanServicerKey,
            signOut: contextStateAuthenticationState.signOut,
            groups: contextStateAuthenticationState.groups
        };
        setStateAuthenticationState(newAuthenticationData);
    };
    const stateObject: IAuthenticationStateTypeState = {
        contextStateAuthenticationState: stateAuthenticationState,
        updateContextStateAuthenticationState: updateAuthenticationType
    };
    return <AuthenticationStateContext.Provider value={stateObject}>{children}</AuthenticationStateContext.Provider>;
};

export default AuthenticationStateProvider;
