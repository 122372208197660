import React, { ReactElement, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import { LoginCallback, SecureRoute } from '@okta/okta-react';
import { Home } from 'components/pages/Home';
import { ClaimForm } from 'components/pages/ClaimForm';
import { ClaimsManagement } from 'components/pages/ClaimsManagement';
import { Contact } from 'components/pages/Contact';
import { Errors } from 'components/pages/Errors';
import { PdfViewer } from 'components/pages/PdfViewer';
import { SCRoutesWrapper } from 'routes/styles';
import { ClaimLookup } from 'components/pages/ClaimLookup';
import { AdminLanding } from 'components/pages/AdminLanding';
import { usePrevious } from 'utils/helpers/helpers';
import { ClaimConfirmation } from 'components/organisms/ClaimConfirmation';
import { FAQ } from 'components/pages/FAQ';
import { AdminGuide } from 'components/pages/AdminGuide';
import { MarketingGuidelines } from 'components/pages/MarketingGuidelines';
import { Alert } from 'components/molecules/Alert';
import { AlertLocationTypesEnum } from 'enums';
import { AuthenticationStateContext, IAuthenticationStateTypeState } from 'context/AuthenticationState';

const RouterSwitch = (): ReactElement | null => {
    const { contextStateAuthenticationState, updateContextStateAuthenticationState } = React.useContext(
        AuthenticationStateContext
    ) as IAuthenticationStateTypeState;
    const history = useHistory();
    const previousValue = usePrevious(contextStateAuthenticationState.isAuthenticated);
    useEffect(() => {
        previousValue !== contextStateAuthenticationState.isAuthenticated &&
            typeof contextStateAuthenticationState.authenticatedRedirectPath !== 'undefined' &&
            contextStateAuthenticationState.isAuthenticated === true &&
            history.push(contextStateAuthenticationState.authenticatedRedirectPath);
    }, [contextStateAuthenticationState.isAuthenticated]);
    /*
    console.info(
        '\n::::::::::::::::::::::::::::::::RouterSwitch:::::::::::::::::::::::::::::::::',
        "\n::localStorage.getItem('clientId')::",
        localStorage.getItem('clientId'),
        '\n::contextStateAuthenticationState::',
        contextStateAuthenticationState,
        '\n::history::',
        history,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );
    */

    return (
        <SCRoutesWrapper>
            <Alert alertLocation={AlertLocationTypesEnum.GLOBAL} />
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path={Routes.HOME} exact component={Home} />
                <Route path={Routes.FAQS} exact component={FAQ} />
                <Route path={Routes.CONTACT} exact component={Contact} />
                <Route path={Routes.CLAIM} exact={true} component={ClaimForm} />
                <Route path={Routes.CLAIM_CONFIRMATION} exact={true} component={ClaimConfirmation} />
                <SecureRoute path={Routes.CLAIMS_MANAGEMENT} exact={true} component={ClaimsManagement} />
                <SecureRoute path={Routes.ADMIN_LANDING} exact={true} component={AdminLanding} />
                <Route path={Routes.CLAIM_LOOKUP} exact={true} component={ClaimLookup} />
                <Route path={Routes.CALLBACK} component={LoginCallback} />
                <Route path={Routes.PDF_VIEWER} component={PdfViewer} />
                <Route path={Routes.ADMIN_GUIDE} exact component={AdminGuide} />
                <Route path={Routes.MARKETING_GUIDELINES} exact component={MarketingGuidelines} />
                <Route path={Routes.ROOT_PRETTY} exact={true} component={Home} />
                <Route path={Routes.HOME_PRETTY} exact={true} component={Home} />
                <Route path={Routes.CLAIM_PRETTY} exact={true} component={ClaimForm} />
                <Route path={Routes.CLAIM_LOOKUP_PRETTY} exact={true} component={ClaimLookup} />
                <Route path={Routes.FAQS_PRETTY} exact component={FAQ} />
                <Route path={Routes.CERTIFICATE_OF_INSURANCE_PRETTY} exact component={Home} />
                <Route path="*" component={Errors} />
            </Switch>
        </SCRoutesWrapper>
    );
};

export { RouterSwitch };
