import * as React from 'react';
import { IOverlayTypeState, IOverlayType } from './OverlayTypes';
import { IChildrenProps } from 'types/children';

export const OverlayContext = React.createContext<IOverlayTypeState | null>(null);
const OverlayProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialState = {
        type: undefined,
        reviewState: false
    };
    const [stateOverlay, setStateOverlay] = React.useState<IOverlayType>(initialState);
    const saveOverlayType: (formData: IOverlayType) => void = (formData: IOverlayType): void => {
        const newTakeoverState: IOverlayType = {
            type: formData.type,
            reviewState: formData.reviewState
        };
        setStateOverlay(newTakeoverState);
    };
    /*
    console.info(
        '\n::::::::::::::::::::::::::::::::OverlayContext:::::::::::::::::::::::::::::::::',
        '\n::stateOverlay::',
        stateOverlay,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );
    */
    const stateObject: IOverlayTypeState = {
        contextStateOverlay: stateOverlay,
        updateContextStateOverlay: saveOverlayType
    };
    return <OverlayContext.Provider value={stateObject}>{children}</OverlayContext.Provider>;
};

export default OverlayProvider;
