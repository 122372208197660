import * as React from 'react';
import { IClaimOptionTypeState, IClaimOptionType } from './ClaimOptionTypes';
import { IChildrenProps } from 'types/children';
import { ClaimStateEnum } from 'enums';

export const ClaimOptionContext = React.createContext<IClaimOptionTypeState | null>(null);
const ClaimOptionProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialState = {
        type: undefined,
        reviewState: ClaimStateEnum.ACTIVE
    };
    const [stateClaimOption, setStateClaimOption] = React.useState<IClaimOptionType>(initialState);
    const saveClaimOption = (formData: IClaimOptionType): void => {
        const newFormData: IClaimOptionType = {
            type: formData.type,
            reviewState: formData.reviewState
        };
        setStateClaimOption(newFormData);
    };
    const stateObject: IClaimOptionTypeState = {
        contextStateClaimOption: stateClaimOption,
        updateContextStateClaimOption: saveClaimOption
    };
    return <ClaimOptionContext.Provider value={stateObject}>{children}</ClaimOptionContext.Provider>;
};

export default ClaimOptionProvider;
