import React, { ReactElement } from 'react';
import { SCHeader, SCKeyValueListWrapper, SCKeyValueSectionWrapper } from './styles';
import { IFormSummarySectionProps } from './types';
import { SCHeadline } from 'styles/global-styles';
import { TSButton } from 'components/atoms/TSButton';
import { KeyValueDisplay } from 'components/atoms/KeyValueDisplay';
import { labelMapIU } from 'components/organisms/FormIU/fieldTypes';
import { ClaimTypesEnum, TimelineState } from 'enums';
import { TSButtonLetterSpacingEnum, TSButtonVariantsEnum, TSButtonSizeEnum } from 'enums/TSButtonVariants';
import { ClaimStateEnum } from 'enums/ClaimState';
import { ClaimFormAdvancementContext, IClaimFormAdvancementTypeState } from 'context/ClaimFormAdvancement';
import { isLast4SSNIdentifierType } from 'utils/helpers/helpers';
import { ClaimOptionContext, IClaimOptionTypeState } from 'context/ClaimOption';
import { ClaimProgressJobLossContext, IClaimProgressJobLossTypeState } from 'context/ClaimProgressJobLoss';
import { ClaimProgressDisabilityContext, IClaimProgressDisabilityTypeState } from 'context/ClaimProgressDisability';
import { DistributorDataContext, IDistributorDataTypeState } from 'context/DistributorData';

const valueCheck = (key: string, value: string, formData: any): string => {
    switch (value) {
        case 'true':
            value = 'yes';
            break;
        case 'false':
            value = 'no';
            break;
    }
    switch (key) {
        case 'borrowerNameFirst':
            value = formData['borrowerNameFirst'] + ' ' + formData['borrowerNameLast'];
            break;
        case 'submitterNameFirst':
            if (!formData['submitterNameFirst']) {
                formData['submitterNameFirst'] = '';
            }
            if (!formData['submitterNameLast']) {
                formData['submitterNameLast'] = '';
            }
            value = formData['submitterNameFirst'] + ' ' + formData['submitterNameLast'];
            break;
    }
    return value;
};
const keyCheck = (key: string): string => {
    switch (key) {
        case 'borrowerNameFirst':
            key = 'borrowerName';
            break;
        case 'borrowerNameLast':
            key = 'SKIP';
            break;
        case 'submitterNameFirst':
            key = 'submitterName';
            break;
        case 'submitterNameLast':
            key = 'SKIP';
            break;
    }
    return key;
};
const FormSummaryInd = (formData: any, columnData: any): ReactElement => {
    return (
        <SCKeyValueListWrapper>
            {Object.keys(formData).map((key: any) => {
                let value = formData[key];
                value = valueCheck(key, value, formData);
                key = keyCheck(key);
                if (columnData.includes(key)) {
                    return <KeyValueDisplay key={key} label={labelMapIU[key]} value={value}></KeyValueDisplay>;
                }
            })}
        </SCKeyValueListWrapper>
    );
};
const FormSummarySection = ({
    title,
    id,
    formData,
    claimType,
    Column1Row1,
    Column1Row2,
    Column2Row1,
    Column2Row2
}: IFormSummarySectionProps): ReactElement => {
    const { contextStateClaimFormAdvancement, updateContextStateClaimFormAdvancement } = React.useContext(
        ClaimFormAdvancementContext
    ) as IClaimFormAdvancementTypeState;
    const { contextStateDistributorData, updateContextStateDistributorData } = React.useContext(
        DistributorDataContext
    ) as IDistributorDataTypeState;
    const {
        updateContextStateClaimProgressJobLoss,
        contextStateClaimProgressJobLoss,
        resetContextStateClaimProgressJobLoss,
        updateClaimProgressJobLossCompletionStatus,
        claimProgressJobLossCompletionStatus,
        updateAllClaimProgressJobLossCompletionStatus
    } = React.useContext(ClaimProgressJobLossContext) as IClaimProgressJobLossTypeState;
    const {
        contextStateClaimProgressDisability,
        updateContextStateClaimProgressDisability,
        resetContextStateClaimProgressDisability,
        claimProgressDisabilityCompletionStatus,
        updateClaimProgressDisabilityCompletionStatus,
        updateAllClaimProgressDisabilityCompletionStatus
    } = React.useContext(ClaimProgressDisabilityContext) as IClaimProgressDisabilityTypeState;
    // const { saveFormType } = React.useContext(FormTypeContext) as IFormTypeState;
    const { contextStateClaimOption, updateContextStateClaimOption } = React.useContext(
        ClaimOptionContext
    ) as IClaimOptionTypeState;
    formData &&
    contextStateDistributorData?.borrowerIdentifierType &&
    !isLast4SSNIdentifierType(contextStateDistributorData.borrowerIdentifierType) &&
    'borrowerSSN' in formData
        ? delete formData.borrowerSSN
        : null;

    return (
        <>
            <SCHeader>
                <SCHeadline>{title}</SCHeadline>
                <TSButton
                    letterSpacing={TSButtonLetterSpacingEnum.NARROW}
                    tsVariant={TSButtonVariantsEnum.UNDERLINE}
                    size={TSButtonSizeEnum.SMALL}
                    ariaLabel={'Button for editing ' + title}
                    onClick={(): void => {
                        updateContextStateClaimOption({ type: claimType, reviewState: ClaimStateEnum.ACTIVE });
                        updateContextStateClaimFormAdvancement({
                            reviewState: ClaimStateEnum.ACTIVE
                        });
                        if (claimType === ClaimTypesEnum.Jobloss) {
                            updateContextStateClaimProgressJobLoss(id, TimelineState.ACTIVE);
                        } else {
                            updateContextStateClaimProgressDisability(id, TimelineState.ACTIVE);
                        }
                    }}
                >
                    Edit Section
                </TSButton>
            </SCHeader>
            <SCKeyValueSectionWrapper>
                {FormSummaryInd(formData, Column1Row1)}
                {Column1Row2 && FormSummaryInd(formData, Column1Row2)}
            </SCKeyValueSectionWrapper>
            <SCKeyValueSectionWrapper>
                {Column2Row1 && FormSummaryInd(formData, Column2Row1)}
                {Column2Row2 && FormSummaryInd(formData, Column2Row2)}
            </SCKeyValueSectionWrapper>
        </>
    );
};

export { FormSummarySection };
