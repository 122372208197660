import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalFooter } from 'components/organisms/GlobalFooter';
import { useQuery } from 'utils/routeUtilities';
import { EConsentService } from 'api/EConsentService';
import { CircularProgress } from '@mui/material';
import {
    $darkGray,
    $deepBlue,
    SCBodyMedium,
    SCContentDirectionWrapper,
    SCContentSectionWrapper,
    SCContentWrapper,
    SCEyebrow,
    SCFooterWrapper,
    SCHeadline,
    SCHeroHeader,
    SCHeroImage,
    SCHeroImageContent,
    SCHeroImageWrapper,
    SCImage,
    SCImageContent,
    SCImageWrapper,
    SCModalWrapper,
    SCPageWrapper,
    SCSpinnerWrapper,
    SCSubHead,
    SCThreeColumnContent,
    SCThreeColumnContentWrapper,
    SCThreeColumnWrapper,
    SCContentSectionResponsiveWrapper,
    SCClaimSubSectionHead,
    SCImageDistributorLogo
} from 'styles/global-styles';
import { SCLeftColumnWrapper, SCRightColumnWrapper } from './styles';
import imageSub from 'assets/images/family_swing.jpg';
import imageSub2 from 'assets/images/backyard_grill.jpg';
import imageSub3 from 'assets/images/table.jpg';
import imageSub4 from 'assets/images/bike_1200.jpg';
import { TSDialog } from 'components/atoms/TSDialog';
import { SCButtonWrapper } from 'components/atoms/TSButton/styles';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { TSQuickLinks } from 'components/atoms/TSQuickLinks';
import { TSPdfViewerComponent } from 'components/molecules/TSPdfViewerComponent';
import { Routes } from 'routes';
import { FAQSummary } from 'components/organisms/FAQSummary';
import { FAQGroupName } from 'enums/FAQGroupName';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum, TextTransformEnum } from 'enums/StyleTypes';
import { checkImageReference } from 'utils/helpers/helpers';
import * as pdfjs from 'pdfjs-dist';
import { CertificateStateContext, ICertificateStateTypeState } from 'context/CertificateState';
import { DistributorDataContext, IDistributorDataTypeState } from 'context/DistributorData';
/**
 * Required by the React-pdf
 */
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Home = (): ReactElement => {
    const history = useHistory();
    const { contextStateDistributorData, updateContextStateDistributorData } = React.useContext(
        DistributorDataContext
    ) as IDistributorDataTypeState;
    const { updateContextStateCertificateState, contextStateCertificateState } = useContext(
        CertificateStateContext
    ) as ICertificateStateTypeState;
    const [clientCertificateURI, setClientCertificateURI] = useState<string>();
    const [eConsentSuccess, setEConsentSuccess] = useState(false);
    const [isEConsentRequest, setIsEConsentRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [validImageURI, setValidImageURI] = useState(false);
    const quickLinks = [
        {
            title: 'Benefits of Payment Guard',
            destination: 'benefitsOfPaymentGuard',
            arrivalOption: 'scroll'
        },
        { title: 'How it Works', destination: 'howItWorks', arrivalOption: 'scroll' },
        { title: 'File a Claim', destination: '/claim', arrivalOption: 'route' },
        { title: 'Check Claim Status', destination: '/claim-lookup', arrivalOption: 'route' },
        {
            title: 'View Certificate of Insurance',
            destination: 'claim-status',
            arrivalOption: 'other',
            onClick: (): void => {
                updateContextStateCertificateState({ certificateView: true });
            }
        },
        { title: 'Contact', destination: '/contact', arrivalOption: 'route' }
    ];
    useEffect(() => {
        checkImageReference(contextStateDistributorData?.client_content?.distributor_logo_uri).then(
            (result: boolean) => {
                setValidImageURI(result);
            }
        );
        if (contextStateDistributorData) {
            if (contextStateDistributorData?.client_content?.certificate_of_insurance_uri) {
                setClientCertificateURI(
                    `${window.origin}${contextStateDistributorData.client_content.certificate_of_insurance_uri}`
                );
            }
        }
    }, [contextStateDistributorData]);
    const query = useQuery();
    useEffect(() => {
        if (
            query.get('displayType') === 'electronicConsent' &&
            query.get('did') &&
            query.get('claimId') &&
            query.get('email')
        ) {
            try {
                setIsLoading(true);
                EConsentService.postClaimWithResponse({
                    claimId: query.get('claimId'),
                    consentResponse: 'True',
                    emailAddress: query.get('email'),
                    distributorName: query.get('did')
                })
                    .then(() => {
                        setEConsentSuccess(true);
                        setIsEConsentRequest(true);
                    })
                    .catch(() => {
                        setEConsentSuccess(false);
                        setIsEConsentRequest(true);
                    });
            } catch (error) {
                setEConsentSuccess(false);
            } finally {
                setIsLoading(false);
            }
        }
    }, []);
    useEffect(() => {
        setIsLoading(
            !contextStateDistributorData?.client_content || contextStateDistributorData?.client_content?.key.length <= 0
        );
    }, [contextStateDistributorData]);
    /*
    console.info(
        '\n::::::::::::::::::::::::::::::::Home:::::::::::::::::::::::::::::::::',
        '\n::contextStateCertificateState::',
        contextStateCertificateState,
        '\n::pdfjs::',
        pdfjs,
        '\n::import.meta.url::',
        import.meta.url,
        '\n::pdfjs.GlobalWorkerOptions.workerSrc::',
        pdfjs.GlobalWorkerOptions.workerSrc,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );
    */

    return (
        <>
            {isLoading && (
                <SCSpinnerWrapper>
                    <CircularProgress
                        sx={{
                            color: (theme): string => {
                                return theme.palette.common.black;
                            },
                            animationDuration: '1550ms'
                        }}
                        size={100}
                        thickness={4}
                    />
                </SCSpinnerWrapper>
            )}
            {!isLoading && (
                <SCPageWrapper>
                    <SCContentWrapper>
                        <SCContentDirectionWrapper
                            flexDirection={FlexDirectionEnum.COLUMN}
                            flexAlignment={FlexAlignItemsEnum.FLEX_START}
                        >
                            {isEConsentRequest && eConsentSuccess && (
                                <SCModalWrapper>
                                    <TSDialog
                                        ariaLabel="eConsentSuccess-dialog"
                                        id="eConsentSuccess-dialog"
                                        closeButtonText="Done"
                                        contentHeaderText="Thank You For Completing Your Claims Submission."
                                        content=" We are working on your claim. Claims are typically processed within 10 business
                                    days. Please call us at 855-997-7272 if you wish to speak to us."
                                        open={isEConsentRequest && eConsentSuccess}
                                        handleClose={(): void => {
                                            setEConsentSuccess(false);
                                            setIsEConsentRequest(false);
                                            history.push({
                                                search: ''
                                            });
                                        }}
                                    ></TSDialog>
                                </SCModalWrapper>
                            )}
                            {isEConsentRequest && !eConsentSuccess && (
                                <SCModalWrapper>
                                    <TSDialog
                                        ariaLabel="eConsentFailed-dialog"
                                        id="eConsentFailed-dialog"
                                        closeButtonText="Done"
                                        contentHeaderText="Oh no! There was an issue submitting your consent request."
                                        content="Your consent has not been submitted. Please resubmit your request. If the issue
                                    persists, please call us at 855-997-7272 or email us at
                                    paymentguard@trustage.com"
                                        open={isEConsentRequest && eConsentSuccess}
                                        handleClose={(): void => {
                                            setEConsentSuccess(false);
                                            setIsEConsentRequest(false);
                                            history.push({
                                                search: ''
                                            });
                                        }}
                                    ></TSDialog>
                                </SCModalWrapper>
                            )}
                            {/*//////////////////////////////////////////////////////////////////////////////////*/}
                            {/*//////////////////////////////////////////////////////////////////////////////////*/}
                            {/*//////////////////////////////////////////////////////////////////////////////////*/}
                            <SCHeroImageWrapper flexDirection={FlexDirectionEnum.ROW_REVERSE}>
                                <SCHeroImage imageURI={imageSub}></SCHeroImage>
                                <SCHeroImageContent>
                                    {validImageURI && (
                                        <SCImageDistributorLogo
                                            src={contextStateDistributorData?.client_content?.distributor_logo_uri}
                                        ></SCImageDistributorLogo>
                                    )}
                                    <SCEyebrow>{contextStateDistributorData?.client_content?.title}</SCEyebrow>
                                    <SCHeroHeader>{contextStateDistributorData?.client_content?.heading}</SCHeroHeader>
                                    <SCHeadline>{contextStateDistributorData?.client_content?.sub_heading}</SCHeadline>
                                    <SCBodyMedium
                                        dangerouslySetInnerHTML={{
                                            __html: contextStateDistributorData?.client_content?.description ?? ''
                                        }}
                                    />
                                </SCHeroImageContent>
                            </SCHeroImageWrapper>
                            <SCContentSectionWrapper
                                flexDirection={FlexDirectionEnum.ROW}
                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                flexGap={'36px'}
                            >
                                <SCLeftColumnWrapper>
                                    <SCContentSectionWrapper
                                        flexDirection={FlexDirectionEnum.COLUMN}
                                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                        flexGap={'36px'}
                                    >
                                        <SCSubHead id="benefitsOfPaymentGuard" textTransform={TextTransformEnum.NONE}>
                                            What does Payment Guard do for you?
                                        </SCSubHead>
                                        <SCThreeColumnWrapper>
                                            <SCThreeColumnContent>
                                                <SCClaimSubSectionHead>
                                                    {
                                                        contextStateDistributorData?.client_content
                                                            ?.key_value_props_1_header
                                                    }
                                                </SCClaimSubSectionHead>
                                                <SCBodyMedium
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            contextStateDistributorData?.client_content
                                                                ?.key_value_props_1_content ?? ''
                                                    }}
                                                />
                                            </SCThreeColumnContent>
                                            <SCThreeColumnContent>
                                                <SCClaimSubSectionHead>
                                                    {
                                                        contextStateDistributorData?.client_content
                                                            ?.key_value_props_2_header
                                                    }
                                                </SCClaimSubSectionHead>
                                                <SCBodyMedium
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            contextStateDistributorData?.client_content
                                                                ?.key_value_props_2_content ?? ''
                                                    }}
                                                />
                                            </SCThreeColumnContent>
                                            <SCThreeColumnContent>
                                                <SCClaimSubSectionHead>
                                                    {
                                                        contextStateDistributorData?.client_content
                                                            ?.key_value_props_3_header
                                                    }
                                                </SCClaimSubSectionHead>
                                                <SCBodyMedium
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            contextStateDistributorData?.client_content
                                                                ?.key_value_props_3_content ?? ''
                                                    }}
                                                />
                                            </SCThreeColumnContent>
                                        </SCThreeColumnWrapper>
                                    </SCContentSectionWrapper>
                                </SCLeftColumnWrapper>
                                <SCRightColumnWrapper>
                                    <TSQuickLinks
                                        ariaLabel={'Quick Links'}
                                        id={'quickLinks'}
                                        links={quickLinks}
                                        title={'Quick Links'}
                                    />
                                </SCRightColumnWrapper>
                            </SCContentSectionWrapper>
                            <SCImageWrapper flexDirection={FlexDirectionEnum.ROW_REVERSE}>
                                <SCImage imageURI={imageSub2}></SCImage>
                                <SCImageContent>
                                    <SCSubHead textColor={$darkGray}>
                                        {contextStateDistributorData?.client_content?.additional_description_header}
                                    </SCSubHead>
                                    <SCBodyMedium
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                contextStateDistributorData?.client_content?.additional_description ??
                                                ''
                                        }}
                                    />
                                </SCImageContent>
                            </SCImageWrapper>
                            <SCImageWrapper id="howItWorks" flexDirection={FlexDirectionEnum.ROW}>
                                <SCImage imageURI={imageSub3}></SCImage>
                                <SCImageContent>
                                    <SCSubHead textColor={$darkGray}>
                                        {contextStateDistributorData?.client_content?.how_it_works_header}
                                    </SCSubHead>
                                    <SCBodyMedium
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                contextStateDistributorData?.client_content?.how_it_works_content ?? ''
                                        }}
                                    />
                                </SCImageContent>
                            </SCImageWrapper>
                            <SCThreeColumnContentWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                flexGap={'24px'}
                            >
                                <SCSubHead textColor={$deepBlue}>Tools for Policy Holders</SCSubHead>
                                <SCContentSectionWrapper
                                    flexDirection={FlexDirectionEnum.ROW}
                                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                    flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                    flexGap={'24px'}
                                >
                                    <SCThreeColumnContent>
                                        <SCClaimSubSectionHead>{'File a Claim'}</SCClaimSubSectionHead>
                                        <h4>{'You can start and submit a policy claim here.'}</h4>
                                        <SCButtonWrapper>
                                            <TSButton
                                                tsVariant={TSButtonVariantsEnum.ARROW}
                                                size={TSButtonSizeEnum.LARGE}
                                                ariaLabel={'submitClaim'}
                                                onClick={(): void => {
                                                    history.push(Routes.CLAIM);
                                                }}
                                            >
                                                File a New Claim
                                            </TSButton>
                                        </SCButtonWrapper>
                                    </SCThreeColumnContent>
                                    <SCThreeColumnContent>
                                        <SCClaimSubSectionHead>{'Check Claim Status'}</SCClaimSubSectionHead>
                                        <p>
                                            {
                                                'View the status of claims that have been submitted for processing already. '
                                            }
                                        </p>
                                        <SCButtonWrapper>
                                            <TSButton
                                                tsVariant={TSButtonVariantsEnum.ARROW}
                                                size={TSButtonSizeEnum.LARGE}
                                                ariaLabel={'searchClaim'}
                                                onClick={(): void => {
                                                    history.push(Routes.CLAIM_LOOKUP);
                                                }}
                                            >
                                                Check Claim Status
                                            </TSButton>
                                        </SCButtonWrapper>
                                    </SCThreeColumnContent>
                                    <SCThreeColumnContent>
                                        <SCClaimSubSectionHead>{'View Certificate of Insurance'}</SCClaimSubSectionHead>
                                        <p>
                                            {
                                                'View, download, and print your Certificate of Insurance for your records here. '
                                            }
                                        </p>
                                        <SCButtonWrapper>
                                            <TSButton
                                                tsVariant={TSButtonVariantsEnum.ARROW}
                                                size={TSButtonSizeEnum.LARGE}
                                                ariaLabel={'viewCertificate'}
                                                onClick={(): void => {
                                                    updateContextStateCertificateState({ certificateView: true });
                                                }}
                                            >
                                                View Certificate of Insurance
                                            </TSButton>
                                        </SCButtonWrapper>
                                    </SCThreeColumnContent>
                                </SCContentSectionWrapper>
                            </SCThreeColumnContentWrapper>
                            <SCImageWrapper flexDirection={FlexDirectionEnum.ROW_REVERSE}>
                                <SCImage imageURI={imageSub4}></SCImage>
                                <SCImageContent>
                                    <SCSubHead textColor={$darkGray}>
                                        {contextStateDistributorData?.client_content?.need_to_file_claim_header}
                                    </SCSubHead>
                                    <SCBodyMedium
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                contextStateDistributorData?.client_content
                                                    ?.need_to_file_claim_content ?? ''
                                        }}
                                    />
                                </SCImageContent>
                            </SCImageWrapper>
                            <SCContentSectionResponsiveWrapper>
                                {contextStateDistributorData?.client_content && (
                                    <FAQSummary
                                        distributorConfigurationData={contextStateDistributorData?.client_content}
                                        groupName={FAQGroupName.general}
                                    ></FAQSummary>
                                )}
                            </SCContentSectionResponsiveWrapper>
                        </SCContentDirectionWrapper>
                    </SCContentWrapper>
                    {/*//////////////////////////////////////////////////////////////////////////////////*/}
                    {/*//////////////////////////////////////////////////////////////////////////////////*/}
                    {/*//////////////////////////////////////////////////////////////////////////////////*/}
                    <SCFooterWrapper>
                        <GlobalFooter />
                    </SCFooterWrapper>
                </SCPageWrapper>
            )}
            {contextStateCertificateState.certificateView && clientCertificateURI && (
                <>
                    <TSDialog
                        id={'viewCertificateOfInsurance-dialog'}
                        ariaLabel={'viewCertificateOfInsurance-dialog'}
                        closeButtonText={'Close'}
                        open={true}
                        hideDialogActions={true}
                        bannerHeaderText={'Certificate of Insurance'}
                        bannerHeaderCloseButtonText={'Close'}
                        dialogSize={'lg'}
                        handleClose={(): void => {
                            updateContextStateCertificateState({ certificateView: false });
                        }}
                    >
                        <div>You can review and download a PDF of your official Certificate of Insurance here.</div>
                        <br />
                        <TSPdfViewerComponent pdfFileUri={clientCertificateURI} downloadLabel={'Download PDF'} />
                    </TSDialog>
                </>
            )}
        </>
    );
};

export { Home };
