import React, { ReactElement } from 'react';
import { SCHelperText, SCLabelWrapper, SCTextFieldWrapper } from './styles';
import { ITSTextFieldProps } from './types';
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material';

const TSTextField = ({
    children,
    id,
    ariaLabel,
    onChange,
    onBlur,
    onFocus,
    value,
    isDisabled,
    variant,
    placeholder,
    label,
    iconType,
    iconLocation,
    isRequired,
    type,
    size,
    helperText,
    maxLength,
    minLength,
    max,
    min,
    minRows,
    multiline,
    readOnly,
    startAdornment
}: ITSTextFieldProps): ReactElement => {
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::TSTextField:::::::::::::::::::::::::::::::::',
    //     '\n::id::',
    //     id,
    //     '\n::label::',
    //     label,
    //     '\n::placeholder::',
    //     placeholder,
    //     '\n::multiline::',
    //     multiline,
    //     '\n::value::',
    //     value,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );
    return (
        <SCTextFieldWrapper>
            <FormControl variant={variant ? variant : 'standard'}>
                {!multiline && <InputLabel htmlFor={id}>{label}</InputLabel>}
                {multiline && (
                    <SCLabelWrapper>
                        {value && value.length > 0 && <InputLabel htmlFor={id}>{label}</InputLabel>}
                    </SCLabelWrapper>
                )}
                <Input
                    id={id}
                    value={value || ''}
                    size={size ? size : 'medium'}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    readOnly={readOnly}
                    disableUnderline={readOnly}
                    required={isRequired}
                    type={type ? type : 'text'}
                    multiline={multiline ? multiline : false}
                    minRows={minRows ? minRows : 1}
                    inputProps={{
                        maxLength: maxLength,
                        minLength: minLength,
                        min: min,
                        max: max,
                        'aria-label': ariaLabel
                    }}
                    startAdornment={
                        (iconLocation && iconLocation === 'start' && iconType && (
                            <InputAdornment position="start">{iconType}</InputAdornment>
                        )) ||
                        startAdornment
                    }
                    endAdornment={
                        iconLocation &&
                        iconLocation === 'end' &&
                        iconType && <InputAdornment position="start">{iconType}</InputAdornment>
                    }
                />
            </FormControl>
            {helperText && <SCHelperText>{helperText}</SCHelperText>}
        </SCTextFieldWrapper>
    );
};

export { TSTextField };
