import React, { ReactElement } from 'react';
import {
    SCColumnHeader,
    SCTSBottomWrapper,
    SCTSColumContent,
    SCTSContactContent,
    SCTSFooterColorBar,
    SCTSFooterColorBarWrapper,
    SCTSFooterContent,
    SCTSFooterContentWrapper,
    SCTSFooterWrapper,
    SCTSTopWrapper
} from './styles';
import { Routes } from 'routes';
import { IGlobalFooterProps } from './types';
import { SCDisclaimer, SCHRElement } from 'styles/global-styles';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum, TSButtonWeightEnum } from 'enums/TSButtonVariants';
import { useHistory } from 'react-router-dom';
import { DistributorDataContext, IDistributorDataTypeState } from 'context/DistributorData';
import { AuthenticationStateContext, IAuthenticationStateTypeState } from 'context/AuthenticationState';

const GlobalFooter = ({ children }: IGlobalFooterProps): ReactElement => {
    const { contextStateAuthenticationState, updateContextStateAuthenticationState } = React.useContext(
        AuthenticationStateContext
    ) as IAuthenticationStateTypeState;
    const { contextStateDistributorData, updateContextStateDistributorData } = React.useContext(
        DistributorDataContext
    ) as IDistributorDataTypeState;
    const history = useHistory();
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::Global Footer:::::::::::::::::::::::::::::::::',
    //     '\n::distributorConfigurationDataState::',
    //     distributorConfigurationDataState,
    //     '\n::contextStateAuthenticationState::',
    //     contextStateAuthenticationState,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <>
            <SCTSFooterWrapper>
                <SCTSFooterContentWrapper>
                    <SCTSFooterContent>
                        <SCTSTopWrapper>
                            <SCTSColumContent>
                                <SCColumnHeader>Contact Us</SCColumnHeader>
                                <SCTSContactContent>
                                    <p>Phone Number</p>
                                    <TSButton
                                        ariaLabel={'Accessibility link'}
                                        size={TSButtonSizeEnum.LARGE}
                                        weight={TSButtonWeightEnum.LIGHT}
                                        tsVariant={TSButtonVariantsEnum.SLIDE}
                                        onClick={(event): void => {
                                            window.location.href = 'tel:855-997-7272';
                                        }}
                                    >
                                        855-997-7272
                                    </TSButton>
                                </SCTSContactContent>
                                <SCTSContactContent>
                                    <p>Email</p>
                                    <TSButton
                                        ariaLabel={'Accessibility link'}
                                        size={TSButtonSizeEnum.LARGE}
                                        weight={TSButtonWeightEnum.LIGHT}
                                        tsVariant={TSButtonVariantsEnum.SLIDE}
                                        onClick={(event): void => {
                                            window.location.href = 'mailto:paymentguard@trustage.com';
                                        }}
                                    >
                                        paymentguard@trustage.com
                                    </TSButton>
                                </SCTSContactContent>
                            </SCTSColumContent>
                            <SCTSColumContent>
                                <SCColumnHeader>Legal</SCColumnHeader>
                                <SCTSContactContent>
                                    <TSButton
                                        ariaLabel={'Accessibility link'}
                                        size={TSButtonSizeEnum.SMALL}
                                        tsVariant={TSButtonVariantsEnum.SLIDE}
                                        onClick={(event): void => {
                                            window.open(
                                                'https://www.cunamutual.com/about-us/general-information/accessibility',
                                                '_blank'
                                            );
                                        }}
                                    >
                                        Accessibility
                                    </TSButton>
                                    <TSButton
                                        ariaLabel={'Accessibility link'}
                                        size={TSButtonSizeEnum.SMALL}
                                        tsVariant={TSButtonVariantsEnum.SLIDE}
                                        onClick={(event): void => {
                                            window.open(
                                                'https://www.cunamutual.com/about-us/general-information/security-information',
                                                '_blank'
                                            );
                                        }}
                                    >
                                        Security Information
                                    </TSButton>
                                    <TSButton
                                        ariaLabel={'Accessibility link'}
                                        size={TSButtonSizeEnum.SMALL}
                                        tsVariant={TSButtonVariantsEnum.SLIDE}
                                        onClick={(event): void => {
                                            window.open('https://www.trustage.com/legal/privacy');
                                        }}
                                    >
                                        Privacy Policy
                                    </TSButton>
                                </SCTSContactContent>
                                <SCTSContactContent></SCTSContactContent>
                            </SCTSColumContent>
                            <SCTSColumContent>
                                {contextStateDistributorData?.client_content && (
                                    <>
                                        <SCColumnHeader>Admin</SCColumnHeader>
                                        <SCTSContactContent>
                                            <TSButton
                                                ariaLabel={'Accessibility link'}
                                                size={TSButtonSizeEnum.SMALL}
                                                tsVariant={TSButtonVariantsEnum.SLIDE}
                                                onClick={(event): void => {
                                                    contextStateAuthenticationState.isAuthenticated
                                                        ? history.push(
                                                              contextStateAuthenticationState.authenticatedRedirectPath
                                                          )
                                                        : history.push(Routes.CLAIMS_MANAGEMENT);
                                                }}
                                            >
                                                {!contextStateAuthenticationState?.isAuthenticated
                                                    ? `Administrator Login`
                                                    : `Claims Management`}
                                            </TSButton>
                                        </SCTSContactContent>
                                    </>
                                )}
                            </SCTSColumContent>
                            <SCTSColumContent>{/*placeholder column*/}</SCTSColumContent>
                        </SCTSTopWrapper>
                        <SCHRElement />
                        <SCTSBottomWrapper>
                            <SCDisclaimer>
                                <p>{contextStateDistributorData?.client_content?.footer_disclosure ?? ''}</p>
                                <p>Corporate Headquarters 5910 Mineral Point Road, Madison WI 53705</p>
                                <p>© TruStage. All Rights Reserved.</p>
                                <SCTSContactContent>
                                    <p>{contextStateDistributorData?.client_content?.adtrax_identifier ?? ''}</p>
                                </SCTSContactContent>
                                {process.env.REACT_APP_SHOW_DEBUG_SITE_CONTEXT &&
                                    process.env.REACT_APP_SHOW_DEBUG_SITE_CONTEXT === 'true' && (
                                        <SCTSContactContent>
                                            <p>
                                                {(contextStateDistributorData?.distributorKey
                                                    ? 'did: ' + contextStateDistributorData?.distributorKey
                                                    : '') +
                                                    (contextStateDistributorData?.loanServicerKey
                                                        ? ', lsid: ' + contextStateDistributorData?.loanServicerKey
                                                        : '')}
                                            </p>
                                        </SCTSContactContent>
                                    )}
                            </SCDisclaimer>
                        </SCTSBottomWrapper>
                    </SCTSFooterContent>
                </SCTSFooterContentWrapper>
                <SCTSFooterColorBarWrapper>
                    <SCTSFooterColorBar />
                </SCTSFooterColorBarWrapper>
            </SCTSFooterWrapper>
        </>
    );
};

export { GlobalFooter };
