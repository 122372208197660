import React, { ReactElement } from 'react';

import { SCDiv, SCLabel, SCValue } from './styles';
import { IKeyValueDisplayProps } from './types';

const KeyValueDisplay = ({ label, value }: IKeyValueDisplayProps): ReactElement => {
    return (
        <SCDiv>
            <SCLabel>{label}:</SCLabel>
            <SCValue>{value}</SCValue>
        </SCDiv>
    );
};

export { KeyValueDisplay };
