import React, { ReactElement } from 'react';
import { SCIconWrapper, SCBarOne, SCBarTwo, SCBarThree } from './styles';
import { ITrueStageLogoUProps } from 'components/atoms/MenuIcon/types';

const MenuIcon = ({ open }: ITrueStageLogoUProps): ReactElement => {
    return (
        <SCIconWrapper>
            <SCBarOne open={open} />
            <SCBarTwo open={open} />
            <SCBarThree open={open} />
        </SCIconWrapper>
    );
};
export default MenuIcon;
