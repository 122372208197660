import { ReactElement } from 'react';

export interface IAdminLandingProps {
    children?: ReactElement | ReactElement[];
}

export enum AdminTypes {
    EXTERNAL_DISTRIBUTOR_ADMIN = 'r-paymentguard_external',
    INTERNAL_ADMIN = 'r-paymentguard_internal'
}
