import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { IOverlayTypeState, OverlayContext } from 'context/Overlay';
import { IOverlayProps } from './types';
import { SCModalWrapper, SCSpinnerWrapper } from 'styles/global-styles';
import { AuthenticationSessionManagement } from 'components/organisms/AuthenticationSessionManagement';
import { getQueryVariables } from 'utils/routeUtilities';
import { EConsentDisclaimer } from 'components/atoms/EConsentDisclaimer';
import { OverlayDisplayTypesEnum, OverlayTypesEnum } from 'enums/OverlayTypes';
import { ClientContextsService } from 'api/ClientContextsService';
import { SCOverlayWrapper } from 'components/organisms/Overlay/styles';
import { TSDialog } from 'components/atoms/TSDialog';
import { DistributorSelector } from 'components/organisms/DistributorSelector';
import { BeforeUnload } from 'components/molecules/BeforeUnload';
import { CircularProgress } from '@mui/material';
import { LocalStorageKeys } from 'enums/LocalStorageKeys';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Routes } from 'routes';
import { CertificateStateContext, ICertificateStateTypeState } from 'context/CertificateState';
import { LocationDescriptor } from 'history';
import { SessionStateEnums } from 'enums/SessionStorageEnums';
import { DistributorDataContext, IDistributorDataTypeState } from 'context/DistributorData';
const Overlay = ({ children }: IOverlayProps): ReactElement => {
    const { contextStateDistributorData, updateContextStateDistributorData } = React.useContext(
        DistributorDataContext
    ) as IDistributorDataTypeState;
    const { updateContextStateCertificateState } = useContext(CertificateStateContext) as ICertificateStateTypeState;
    const { contextStateOverlay, updateContextStateOverlay } = React.useContext(OverlayContext) as IOverlayTypeState;
    const [isLoading, setIsLoading] = useState(false);
    const [modalEDeliveryState, setModalEDeliveryState] = useState<boolean>(false);
    const queryVariables = getQueryVariables();
    const launchDarklyFlags = useFlags();
    const history = useHistory();
    const routeMatchRoot = useRouteMatch({
        path: Routes.ROOT_PRETTY,
        exact: true
    });
    const routeMatchClaim = useRouteMatch({
        path: Routes.CLAIM_PRETTY,
        exact: true
    });
    const routeMatchClaimLookUp = useRouteMatch({
        path: Routes.CLAIM_LOOKUP_PRETTY,
        exact: true
    });
    const routeMatchFAQ = useRouteMatch({
        path: Routes.FAQS_PRETTY,
        exact: true
    });
    const routeMatchCertificate = useRouteMatch({
        path: Routes.CERTIFICATE_OF_INSURANCE_PRETTY,
        exact: true
    });
    const getClientConfiguration = async (distributorKey: string, loanServicerKey: string): Promise<void> => {
        sessionStorage.setItem(SessionStateEnums.ACTIVE_REQUEST, JSON.stringify(true));
        setIsLoading(true);
        await ClientContextsService(distributorKey, loanServicerKey)
            .then((value: unknown | any) => {
                localStorage.removeItem(LocalStorageKeys.DISTRIBUTOR_KEY);
                localStorage.removeItem(LocalStorageKeys.LOAN_SERVICER_KEY);
                if (distributorKey) localStorage.setItem(LocalStorageKeys.DISTRIBUTOR_KEY, distributorKey as string);
                if (loanServicerKey)
                    localStorage.setItem(LocalStorageKeys.LOAN_SERVICER_KEY, loanServicerKey as string);
                updateContextStateDistributorData(value.payload);
            })
            .catch(() => {
                resetPartnerSelection();
            });
        setIsLoading(false);
        sessionStorage.setItem(SessionStateEnums.ACTIVE_REQUEST, JSON.stringify(false));
    };
    const resetPartnerSelection = (): void => {
        localStorage.removeItem(LocalStorageKeys.CLIENT_KEY); //deprecated key <- delete this line later
        localStorage.removeItem(LocalStorageKeys.DISTRIBUTOR_KEY);
        localStorage.removeItem(LocalStorageKeys.LOAN_SERVICER_KEY);
        updateContextStateOverlay({ type: OverlayTypesEnum.TAKEOVER, reviewState: true });
    };
    useEffect(() => {
        contextStateDistributorData.distributorKey &&
            contextStateDistributorData.distributorKey?.length > 0 &&
            updateContextStateOverlay({ type: undefined, reviewState: false });
    }, [contextStateDistributorData]);
    useEffect(() => {
        const currentParamDID = routeMatchRoot?.params['did'];
        const currentParamDIDClaim = routeMatchClaim?.params['did'];
        const currentParamDIDClaimLookUp = routeMatchClaimLookUp?.params['did'];
        const currentParamDIDFAQ = routeMatchFAQ?.params['did'];
        const currentParamDIDCertificate = routeMatchCertificate?.params['did'];
        const isMatchRoot: boolean = launchDarklyFlags.paymentGuardDistributorsInformation.some(
            (obj: { Key: string }) => obj.Key === currentParamDID
        );
        const isMatchClaim: boolean = launchDarklyFlags.paymentGuardDistributorsInformation.some(
            (obj: { Key: string }) => obj.Key === currentParamDIDClaim
        );
        const isMatchClaimLookUp: boolean = launchDarklyFlags.paymentGuardDistributorsInformation.some(
            (obj: { Key: string }) => obj.Key === currentParamDIDClaimLookUp
        );
        const isMatchFAQ: boolean = launchDarklyFlags.paymentGuardDistributorsInformation.some(
            (obj: { Key: string }) => obj.Key === currentParamDIDFAQ
        );
        const isMatchCertificate: boolean = launchDarklyFlags.paymentGuardDistributorsInformation.some(
            (obj: { Key: string }) => obj.Key === currentParamDIDCertificate
        );
        const routeMatchAndInitConfiguration = (
            routeValid: object | null,
            routeMatchDID: boolean,
            currentParamDID: string,
            route: LocationDescriptor<unknown>
        ): void => {
            !JSON.parse(sessionStorage.getItem(SessionStateEnums.ACTIVE_REQUEST) as string) &&
                routeValid &&
                routeMatchDID &&
                getClientConfiguration(currentParamDID, '')
                    .then(() => {
                        contextStateDistributorData.distributorKey &&
                            contextStateDistributorData.distributorKey?.length > 0 &&
                            updateContextStateOverlay({ type: undefined, reviewState: false });
                        routeValid && history.push(route);
                        routeMatchCertificate && updateContextStateCertificateState({ certificateView: true });
                    })
                    .catch(() => {
                        resetPartnerSelection();
                    });
        };
        isMatchRoot && routeMatchAndInitConfiguration(routeMatchRoot, isMatchRoot, currentParamDID, Routes.HOME);
        isMatchClaim &&
            routeMatchAndInitConfiguration(routeMatchClaim, isMatchClaim, currentParamDIDClaim, Routes.CLAIM);
        isMatchFAQ && routeMatchAndInitConfiguration(routeMatchFAQ, isMatchFAQ, currentParamDIDFAQ, Routes.FAQS);
        isMatchClaimLookUp &&
            routeMatchAndInitConfiguration(
                routeMatchClaimLookUp,
                isMatchClaimLookUp,
                currentParamDIDClaimLookUp,
                Routes.CLAIM_LOOKUP
            );
        isMatchCertificate &&
            routeMatchAndInitConfiguration(
                routeMatchCertificate,
                isMatchCertificate,
                currentParamDIDCertificate,
                Routes.HOME
            );
    }, [contextStateDistributorData.distributorKey]);
    useEffect(() => {
        const activeRequest = JSON.parse(sessionStorage.getItem(SessionStateEnums.ACTIVE_REQUEST) as string);
        if (queryVariables && queryVariables['displayType'] === OverlayDisplayTypesEnum.E_CONSENT_LEGAL) {
            setModalEDeliveryState(true);
        }
        if (queryVariables && Object.prototype.hasOwnProperty.call(queryVariables, 'did') && !activeRequest) {
            /** This "lsid" check is short-term and will be built-upon later after a design spike */
            const lsidQueryParam = Object.prototype.hasOwnProperty.call(queryVariables, 'lsid')
                ? queryVariables['lsid']
                : '';
            getClientConfiguration(queryVariables['did'], lsidQueryParam);
        }
    }, [window.location.search]);
    useEffect(() => {
        window.addEventListener('error', (e) => {
            // TODO Resolve underlying issue
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
                const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, [window]);
    useEffect(() => {
        const localStorageDistributorKey = localStorage.getItem(LocalStorageKeys.DISTRIBUTOR_KEY);
        const localStorageLoanServicerKey = localStorage.getItem(LocalStorageKeys.LOAN_SERVICER_KEY);
        const activeRequest = JSON.parse(sessionStorage.getItem(SessionStateEnums.ACTIVE_REQUEST) as string);
        if (!activeRequest && localStorageDistributorKey) {
            getClientConfiguration(localStorageDistributorKey as string, localStorageLoanServicerKey as string).then(
                () => {}
            );
        } else if (!activeRequest && !localStorage.getItem(LocalStorageKeys.DISTRIBUTOR_KEY)) {
            resetPartnerSelection();
        }
    }, []);
    /*
    console.info(
        '\n::::::::::::::::::::::::::::::::Overlay:::::::::::::::::::::::::::::::::',
        '\n::queryVariables::',
        queryVariables,
        '\n::contextStateOverlay::',
        contextStateOverlay,
        '\n::contextStateDistributorData::',
        contextStateDistributorData,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );
    */

    return (
        <SCOverlayWrapper>
            {(!contextStateOverlay['reviewState'] && !isLoading && children) ||
                (contextStateOverlay['reviewState'] &&
                    contextStateOverlay['type'] === OverlayTypesEnum.TAKEOVER &&
                    !isLoading && <DistributorSelector></DistributorSelector>)}
            {modalEDeliveryState && (
                <SCModalWrapper>
                    <TSDialog
                        ariaLabel="econsent-dialog"
                        id="econsent-dialog"
                        closeButtonText="Done"
                        open={modalEDeliveryState}
                        fullscreen={true}
                        handleClose={(): void => {
                            setModalEDeliveryState(false);
                        }}
                    >
                        <EConsentDisclaimer />
                    </TSDialog>
                </SCModalWrapper>
            )}
            {isLoading && (
                <SCSpinnerWrapper>
                    <CircularProgress
                        sx={{
                            color: (theme): string => {
                                return theme.palette.common.black;
                            },
                            animationDuration: '1550ms'
                        }}
                        size={100}
                        thickness={4}
                    />
                </SCSpinnerWrapper>
            )}
            <AuthenticationSessionManagement />
            <BeforeUnload
                isLoggedIn={false}
                nativeDialogue={false}
                customDialogue={false}
                modalEnabled={false}
                onContinue={(): void => {}}
                allowedRoutes={[]}
                onCancel={(): void => {}}
                beforeUnloadExecute={(): void => {
                    sessionStorage.removeItem(SessionStateEnums.ACTIVE_REQUEST);
                }}
            />
        </SCOverlayWrapper>
    );
};

export { Overlay };
