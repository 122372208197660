import * as React from 'react';
import { IDistributorDataTypeState, IDistributorDataType } from './DistributorDataTypes';
import { IChildrenProps } from 'types/children';

export const DistributorDataContext = React.createContext<IDistributorDataTypeState | null>(null);
const DistributorDataProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialState: IDistributorDataType = {
        client_content: {
            key: '',
            name: '',
            certificate_of_insurance_uri: '',
            coverage_duration: '',
            distributor_launch_date: '',
            distributor_benefit_equivalency: '',
            distributor_link: '',
            distributor_logo_uri: '',
            adtrax_identifier: '',
            faq_collections: [],
            footer_disclosure: '',
            claim_options: [],
            claim_subHeader: '',
            claim_peril_1: '',
            claim_peril_2: '',
            claim_peril_3: '',
            claim_peril_4: '',
            title: '',
            heading: '',
            sub_heading: '',
            how_it_works_header: '',
            how_it_works_content: '',
            description: '',
            additional_description_header: '',
            additional_description: '',
            key_value_props_1_header: '',
            key_value_props_1_content: '',
            key_value_props_2_header: '',
            key_value_props_2_content: '',
            key_value_props_3_header: '',
            key_value_props_3_content: '',
            need_to_file_claim_content: '',
            need_to_file_claim_header: ''
        },
        loanServicerKey: '',
        loanServicerName: '',
        distributorKey: '',
        distributorName: '',
        borrowerIdentifierType: 0
    };
    const [stateDistributorData, setStateDistributorData] = React.useState<IDistributorDataType>(initialState);

    const saveClientContextDataState = (distributorConfigurationData: IDistributorDataType): void => {
        const newDistributorConfigurationData: IDistributorDataType = {
            client_content: distributorConfigurationData.client_content,
            distributorKey: distributorConfigurationData.distributorKey,
            distributorName: distributorConfigurationData.distributorName,
            loanServicerKey: distributorConfigurationData.loanServicerKey,
            loanServicerName: distributorConfigurationData.loanServicerName,
            borrowerIdentifierType: distributorConfigurationData.borrowerIdentifierType
        };
        setStateDistributorData({ ...newDistributorConfigurationData });
        // console.info(
        //     '\n::::::::::::::::::::::::::::::DistributorConfigurationStateContext::saveDistributorConfigurationDataState:::::::::::::::::::::::::::::::::',
        //     '\n::distributorConfigurationDataState::',
        //     distributorConfigurationDataState,
        //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
        // );
    };

    const stateObject: IDistributorDataTypeState = {
        contextStateDistributorData: stateDistributorData,
        updateContextStateDistributorData: saveClientContextDataState
    };
    return <DistributorDataContext.Provider value={stateObject}>{children}</DistributorDataContext.Provider>;
};

export default DistributorDataProvider;
