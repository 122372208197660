import {
    ClaimSurveyHeadCell,
    Data,
    csvData,
    PostClaimSurveyCSVData,
    csvHeadCell
} from 'components/pages/ClaimsManagement';
import { SurveyCSVLabels, editFormLabels, claimBenefitFormLabels } from 'support/formLabels';
import { IClaimsManagementClaimBenefitsData } from 'context/ClaimManagement';
export const createData = (
    nameFirst: string,
    nameLast: string,
    distributorName: string,
    loanServicerName: string,
    claimNumber: number,
    claimStatus: string,
    jobLossReason: string,
    borrowerIdentifierKey: string,
    claimCreateDate: string,
    peril: string,
    loanNumber: number,
    certificateEffectiveDate: string,
    dateIncurred: string,
    dateOfClaimDecision: string,
    claimDecision: string,
    amountPayable: string,
    disablingCondition: string,
    decisionReason: string,
    dateDecisionEmailSent: string,
    fastTrack: string,
    additionalInfo: string,
    eConsent: string,
    nameOfClaimSpecialist: string,
    notes: string,
    last4SSN: string,
    editStatus: boolean,
    ClaimBenefits: object,
    emailAddress: string,
    allClaimDetailsLookupIndex: number
): Data => {
    return {
        nameFirst,
        nameLast,
        distributorName,
        loanServicerName,
        claimNumber,
        claimStatus,
        jobLossReason,
        borrowerIdentifierKey,
        claimCreateDate,
        peril,
        loanNumber,
        certificateEffectiveDate,
        dateIncurred,
        dateOfClaimDecision,
        claimDecision,
        amountPayable,
        disablingCondition,
        decisionReason,
        dateDecisionEmailSent,
        fastTrack,
        additionalInfo,
        eConsent,
        nameOfClaimSpecialist,
        notes,
        last4SSN,
        editStatus,
        ClaimBenefits,
        emailAddress,
        allClaimDetailsLookupIndex
    };
};
export const createCSVData = (
    nameFirst: string,
    nameLast: string,
    distributorName: string,
    loanServicerName: string,
    claimNumber: number,
    jobLossReason: string,
    borrowerIdentifierKey: string,
    claimCreateDate: string,
    peril: string,
    benefitId: string,
    benefitAmountPayableSumTotal: string,
    loanNumber: string,
    certificateEffectiveDate: string,
    dateIncurred: string,
    dateOfClaimDecision: string,
    claimDecision: string,
    amountPayable: string,
    disablingCondition: string,
    decisionReason: string,
    dateDecisionEmailSent: string,
    fastTrack: string,
    additionalInfo: string,
    eConsent: string,
    nameOfClaimSpecialist: string,
    notes: string,
    last4SSN: string,
    isDeleted: string,
    editStatus: boolean
): csvData => {
    return {
        nameFirst,
        nameLast,
        distributorName,
        loanServicerName,
        claimNumber,
        jobLossReason,
        borrowerIdentifierKey,
        claimCreateDate,
        peril,
        benefitId,
        benefitAmountPayableSumTotal,
        loanNumber,
        certificateEffectiveDate,
        dateIncurred,
        dateOfClaimDecision,
        claimDecision,
        amountPayable,
        disablingCondition,
        decisionReason,
        dateDecisionEmailSent,
        fastTrack,
        additionalInfo,
        eConsent,
        nameOfClaimSpecialist,
        notes,
        last4SSN,
        isDeleted,
        editStatus
    };
};
export const amountPayableSum = (benefitsArray: IClaimsManagementClaimBenefitsData[]): number => {
    if (!benefitsArray || benefitsArray.length === 0) {
        return 0.0;
    }
    return benefitsArray.reduce((sum: number, current: any): number => {
        return current.AmountPayable && current.IsDeleted !== true && parseFloat(current.AmountPayable)
            ? parseFloat(current.AmountPayable) + sum
            : sum;
    }, 0.0);
};
export const createPostClaimSurveyCSVData = (
    claimNumber: string,
    email: string,
    product: string,
    orig_Product: string,
    name: string,
    claimPaymentAmount: string,
    address: string,
    creditUnion: string,
    adjuster: string,
    policyNumber: string,
    policy_Number: string,
    nFCU: string,
    fileName: string,
    lastSampleMonth: string,
    teamLead: string,
    beneficiarySettlementType: string,
    benePhone: string,
    beneRelationship: string,
    cellPhone1: string,
    cellPhoneNumber2: string,
    claimDurationInMonths: string,
    claimEnteredDate: string,
    claimPaymentDate: string,
    claimStatus: string,
    claimType: string,
    closeDate: string,
    closeReason: string,
    complexityRating: string,
    contestable: string,
    contractNumber: string,
    cover_Type: string,
    coverageName: string,
    coveragePaymentAmt: string,
    cP_Type: string,
    cust_ID: string,
    dateofDeath: string,
    dPProductRelease: string,
    fNOLClaim: string,
    informant_FirstName: string,
    informant_LastName: string,
    informantRelationship: string,
    insuredName: string,
    member_FirstName: string,
    member_LastName: string,
    noticeReceivedDate: string,
    noticeReportDate: string,
    num_OfClaimsSubmittedPrev_Yr: string,
    ownerSite: string,
    payeeName: string,
    paymentDate: string,
    paymentType: string,
    personOrOrganization: string,
    phoneNumber: string,
    site: string,
    surplus_Lines: string,
    thresholdLevel: string,
    notificationType: string,
    teamType: string,
    annuityAdjuster: string,
    deskCode: string,
    input_Individuals_Birth_Date: string,
    children_In_Household_Count: string,
    presence_Of_Senior_Adult_Flag: string,
    college_Graduate_Flag: string,
    marital_Status_Code: string,
    marital_Newlywed_Flag: string,
    education_Of_1st_Individual_Code: string,
    race_Code: string,
    estimated_Income_Code: string,
    net_Worth_Code: string,
    stocks_Bonds_Flag: string,
    home_Owner_Or_Renter_Indicator: string,
    military_Veteran_Flag: string,
    gender: string,
    birth_Date: string,
    age: string,
    age_Cats: string,
    memberNumber: string,
    state: string,
    short_claimnumber: string,
    fillingMethod: string,
    adjusterFirstName: string
): PostClaimSurveyCSVData => {
    return {
        claimNumber,
        email,
        product,
        orig_Product,
        name,
        claimPaymentAmount,
        address,
        creditUnion,
        adjuster,
        policyNumber,
        policy_Number,
        nFCU,
        fileName,
        lastSampleMonth,
        teamLead,
        beneficiarySettlementType,
        benePhone,
        beneRelationship,
        cellPhone1,
        cellPhoneNumber2,
        claimDurationInMonths,
        claimEnteredDate,
        claimPaymentDate,
        claimStatus,
        claimType,
        closeDate,
        closeReason,
        complexityRating,
        contestable,
        contractNumber,
        cover_Type,
        coverageName,
        coveragePaymentAmt,
        cP_Type,
        cust_ID,
        dateofDeath,
        dPProductRelease,
        fNOLClaim,
        informant_FirstName,
        informant_LastName,
        informantRelationship,
        insuredName,
        member_FirstName,
        member_LastName,
        noticeReceivedDate,
        noticeReportDate,
        num_OfClaimsSubmittedPrev_Yr,
        ownerSite,
        payeeName,
        paymentDate,
        paymentType,
        personOrOrganization,
        phoneNumber,
        site,
        surplus_Lines,
        thresholdLevel,
        notificationType,
        teamType,
        annuityAdjuster,
        deskCode,
        input_Individuals_Birth_Date,
        children_In_Household_Count,
        presence_Of_Senior_Adult_Flag,
        college_Graduate_Flag,
        marital_Status_Code,
        marital_Newlywed_Flag,
        education_Of_1st_Individual_Code,
        race_Code,
        estimated_Income_Code,
        net_Worth_Code,
        stocks_Bonds_Flag,
        home_Owner_Or_Renter_Indicator,
        military_Veteran_Flag,
        gender,
        birth_Date,
        age,
        age_Cats,
        memberNumber,
        state,
        short_claimnumber,
        fillingMethod,
        adjusterFirstName
    };
};
/**
 * order should match the order of rows data to coincide with CSV columns
 * */
export const CSVHeadCells: readonly csvHeadCell[] = [
    {
        id: 'nameFirst',
        numeric: false,
        disablePadding: true,
        label: editFormLabels.borrowerNameFirst
    },
    {
        id: 'nameLast',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.borrowerNameLast
    },
    {
        id: 'distributorName',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.distributorName
    },
    {
        id: 'loanServicerName',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.loanServicerName
    },
    {
        id: 'claimNumber',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimNumber
    },
    {
        id: 'jobLossReason',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.reasonForJobLoss
    },
    {
        id: 'borrowerIdentifierKey',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimIdentifier
    },
    {
        id: 'claimCreateDate',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.dateFiled
    },
    {
        id: 'peril',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.lossType
    },
    {
        id: 'benefitId',
        numeric: false,
        disablePadding: false,
        label: claimBenefitFormLabels.benefitId
    },
    {
        id: 'benefitAmountPayableSumTotal',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.benefitAmountPayableSumTotal
    },
    {
        id: 'loanNumber',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.loanNumber
    },
    {
        id: 'certificateEffectiveDate',
        numeric: false,
        disablePadding: false,
        label: claimBenefitFormLabels.dateCertificateEffective
    },
    {
        id: 'dateIncurred',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.incurredDate
    },
    {
        id: 'dateOfClaimDecision',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimDecisionDate
    },
    {
        id: 'claimDecision',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimDecision
    },
    {
        id: 'amountPayable',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.amountPayable
    },
    {
        id: 'disablingCondition',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.disablingCondition
    },
    {
        id: 'decisionReason',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.decisionReason
    },
    {
        id: 'dateDecisionEmailSent',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.dateDecisionSent
    },
    {
        id: 'fastTrack',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.fastTracked
    },
    {
        id: 'additionalInfo',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.additionalInformation
    },
    {
        id: 'eConsent',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.eConsentReceived
    },
    {
        id: 'nameOfClaimSpecialist',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.claimSpecialistName
    },
    {
        id: 'notes',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.notes
    },
    {
        id: 'last4SSN',
        numeric: false,
        disablePadding: false,
        label: editFormLabels.last4SSN
    }
];
/**
 * order should match the order of rows data to coincide with Claim Survey CSV columns
 * */
export const SurveyCSVHeadCells: readonly ClaimSurveyHeadCell[] = [
    {
        id: 'claimNumber',
        numeric: false,
        disablePadding: true,
        label: SurveyCSVLabels.claimNumber
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.email
    },
    {
        id: 'product',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.product
    },
    {
        id: 'orig_Product',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.orig_Product
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.name
    },
    {
        id: 'claimPaymentAmount',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.claimPaymentAmount
    },
    {
        id: 'address',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.address
    },
    {
        id: 'creditUnion',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.creditUnion
    },
    {
        id: 'adjuster',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.adjuster
    },
    {
        id: 'policyNumber',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.policyNumber
    },
    {
        id: 'policy_Number',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.policy_Number
    },
    {
        id: 'nFCU',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.nFCU
    },
    {
        id: 'fileName',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.fileName
    },
    {
        id: 'lastSampleMonth',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.lastSampleMonth
    },
    {
        id: 'teamLead',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.teamLead
    },
    {
        id: 'beneficiarySettlementType',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.beneficiarySettlementType
    },
    {
        id: 'benePhone',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.benePhone
    },
    {
        id: 'beneRelationship',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.beneRelationship
    },
    {
        id: 'cellPhone1',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.cellPhone1
    },
    {
        id: 'cellPhoneNumber2',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.cellPhoneNumber2
    },
    {
        id: 'claimDurationInMonths',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.claimDurationInMonths
    },
    {
        id: 'claimEnteredDate',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.claimEnteredDate
    },
    {
        id: 'claimPaymentDate',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.claimPaymentDate
    },
    {
        id: 'claimStatus',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.claimStatus
    },
    {
        id: 'claimType',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.claimType
    },
    {
        id: 'closeDate',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.closeDate
    },
    {
        id: 'closeReason',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.closeReason
    },
    {
        id: 'complexityRating',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.complexityRating
    },
    {
        id: 'contestable',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.contestable
    },
    {
        id: 'contractNumber',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.contractNumber
    },
    {
        id: 'cover_Type',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.cover_Type
    },
    {
        id: 'coverageName',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.coverageName
    },
    {
        id: 'coveragePaymentAmt',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.coveragePaymentAmt
    },
    {
        id: 'cP_Type',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.cP_Type
    },
    {
        id: 'cust_ID',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.cust_ID
    },
    {
        id: 'dateofDeath',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.dateofDeath
    },
    {
        id: 'dPProductRelease',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.dPProductRelease
    },
    {
        id: 'fNOLClaim',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.fNOLClaim
    },
    {
        id: 'informant_FirstName',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.informant_FirstName
    },
    {
        id: 'informant_LastName',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.informant_LastName
    },
    {
        id: 'informantRelationship',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.informantRelationship
    },
    {
        id: 'insuredName',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.insuredName
    },
    {
        id: 'member_FirstName',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.member_FirstName
    },
    {
        id: 'member_LastName',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.member_LastName
    },
    {
        id: 'noticeReceivedDate',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.noticeReceivedDate
    },
    {
        id: 'noticeReportDate',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.noticeReportDate
    },
    {
        id: 'num_OfClaimsSubmittedPrev_Yr',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.num_OfClaimsSubmittedPrev_Yr
    },
    {
        id: 'ownerSite',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.ownerSite
    },
    {
        id: 'payeeName',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.payeeName
    },
    {
        id: 'paymentDate',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.paymentDate
    },
    {
        id: 'paymentType',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.paymentType
    },
    {
        id: 'personOrOrganization',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.personOrOrganization
    },
    {
        id: 'phoneNumber',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.phoneNumber
    },
    {
        id: 'site',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.site
    },
    {
        id: 'surplus_Lines',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.surplus_Lines
    },
    {
        id: 'thresholdLevel',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.thresholdLevel
    },
    {
        id: 'notificationType',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.notificationType
    },
    {
        id: 'teamType',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.teamType
    },
    {
        id: 'annuityAdjuster',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.annuityAdjuster
    },
    {
        id: 'deskCode',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.deskCode
    },
    {
        id: 'input_Individuals_Birth_Date',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.input_Individuals_Birth_Date
    },
    {
        id: 'children_In_Household_Count',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.children_In_Household_Count
    },
    {
        id: 'presence_Of_Senior_Adult_Flag',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.presence_Of_Senior_Adult_Flag
    },
    {
        id: 'college_Graduate_Flag',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.college_Graduate_Flag
    },
    {
        id: 'marital_Status_Code',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.marital_Status_Code
    },
    {
        id: 'marital_Newlywed_Flag',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.marital_Newlywed_Flag
    },
    {
        id: 'education_Of_1st_Individual_Code',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.education_Of_1st_Individual_Code
    },
    {
        id: 'race_Code',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.race_Code
    },
    {
        id: 'estimated_Income_Code',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.estimated_Income_Code
    },
    {
        id: 'net_Worth_Code',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.net_Worth_Code
    },
    {
        id: 'stocks_Bonds_Flag',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.stocks_Bonds_Flag
    },
    {
        id: 'home_Owner_Or_Renter_Indicator',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.home_Owner_Or_Renter_Indicator
    },
    {
        id: 'military_Veteran_Flag',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.military_Veteran_Flag
    },
    {
        id: 'gender',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.gender
    },
    {
        id: 'birth_Date',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.birth_Date
    },
    {
        id: 'age',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.age
    },
    {
        id: 'age_Cats',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.age_Cats
    },
    {
        id: 'memberNumber',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.memberNumber
    },
    {
        id: 'state',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.state
    },
    {
        id: 'short_claimnumber',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.short_claimnumber
    },
    {
        id: 'fillingMethod',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.fillingMethod
    },
    {
        id: 'adjusterFirstName',
        numeric: false,
        disablePadding: false,
        label: SurveyCSVLabels.adjusterFirstName
    }
];
