import React, { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { XIcon } from 'components/atoms/XIcon';
import { Button } from '@mui/material';
import {
    SCInnerRootWrapper,
    SCAlertContentWrapper,
    SCAlertOuterContentWrapper,
    SCTextWrapper,
    SCCTAButtonWrapper,
    SCOuterRootWrapper
} from './styles';
import { IAlertProps } from './types';
import { AlertCTATypeEnum, AlertLocationTypesEnum } from 'enums/AlertEnums';
import { AlertContext, IAlertTypeState } from 'context/Alert';

const Alert = ({ handleDismiss, styleMessageLeftPadding, alertLocation }: IAlertProps): ReactElement => {
    const { contextStateAlert, updateContextStateAlert } = React.useContext(AlertContext) as IAlertTypeState;
    const location = useLocation();
    useEffect(() => {
        location.pathname !== '/' &&
            contextStateAlert.active &&
            contextStateAlert.dismissible &&
            updateContextStateAlert({ alertLocation: AlertLocationTypesEnum.GLOBAL });
    }, [location]);
    const history = useHistory();
    /*
    console.info(
        '\n::::::::::::::::::::::::::::::::Alert:::::::::::::::::::::::::::::::::',
        '\n::contextStateAlert::',
        contextStateAlert,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );
    */

    return (
        <>
            {contextStateAlert?.active && contextStateAlert?.alertLocation === alertLocation && (
                <SCOuterRootWrapper>
                    <SCInnerRootWrapper alertType={contextStateAlert.type}>
                        <SCAlertContentWrapper paddingLeft={styleMessageLeftPadding}>
                            {contextStateAlert.message}
                            {contextStateAlert.callToAction && (
                                <>
                                    {(contextStateAlert.callToActionType === AlertCTATypeEnum.EMAIL && (
                                        <SCCTAButtonWrapper>
                                            <Button
                                                id="cta-button"
                                                aria-label="alert email button"
                                                onClick={(): void => {
                                                    window.location.href = 'mailto:paymentguard@trustage.com';
                                                }}
                                                variant="text"
                                                size="small"
                                            >
                                                Contact Us
                                            </Button>
                                        </SCCTAButtonWrapper>
                                    )) ||
                                        (contextStateAlert.callToActionType === AlertCTATypeEnum.LINK && (
                                            <SCCTAButtonWrapper>
                                                <Button
                                                    id="cta-button"
                                                    aria-label="alert message button"
                                                    onClick={(): void => {
                                                        window.location.href = contextStateAlert.callToActionURL
                                                            ? contextStateAlert.callToActionURL
                                                            : '';
                                                    }}
                                                    variant="text"
                                                    size="small"
                                                >
                                                    {contextStateAlert.message}
                                                </Button>
                                            </SCCTAButtonWrapper>
                                        )) ||
                                        (contextStateAlert.callToActionType === AlertCTATypeEnum.NONE && (
                                            <SCCTAButtonWrapper>
                                                <Button
                                                    id="cta-button"
                                                    aria-label="alert message button"
                                                    onClick={(): void => {
                                                        window.location.href = contextStateAlert.callToActionURL
                                                            ? contextStateAlert.callToActionURL
                                                            : '';
                                                    }}
                                                    variant="text"
                                                    size="small"
                                                >
                                                    {contextStateAlert.message}
                                                </Button>
                                            </SCCTAButtonWrapper>
                                        )) || (
                                            <SCCTAButtonWrapper>
                                                <Button
                                                    id="cta-button"
                                                    aria-label="close alert button"
                                                    onClick={(): void => {
                                                        if (handleDismiss) {
                                                            handleDismiss();
                                                            updateContextStateAlert({
                                                                alertLocation: contextStateAlert.alertLocation
                                                            });
                                                        }
                                                        history.push(
                                                            contextStateAlert.callToActionURL as LocationDescriptor<unknown>
                                                        );
                                                    }}
                                                    variant="text"
                                                    size="small"
                                                >
                                                    <SCTextWrapper>{contextStateAlert.callToAction}</SCTextWrapper>
                                                </Button>
                                            </SCCTAButtonWrapper>
                                        )}
                                </>
                            )}
                        </SCAlertContentWrapper>
                        <SCAlertOuterContentWrapper>
                            {contextStateAlert.dismissible && (
                                <Button
                                    id="cta-button"
                                    aria-label="alert close button"
                                    onClick={(): void => {
                                        if (handleDismiss) {
                                            handleDismiss();
                                        }
                                        updateContextStateAlert({ alertLocation: contextStateAlert.alertLocation });
                                    }}
                                    variant="text"
                                    size="small"
                                    startIcon={<XIcon size={'sm'} />}
                                >
                                    <SCTextWrapper>Close</SCTextWrapper>
                                </Button>
                            )}
                        </SCAlertOuterContentWrapper>
                    </SCInnerRootWrapper>
                </SCOuterRootWrapper>
            )}
        </>
    );
};

export { Alert };
