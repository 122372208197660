import React, { ReactElement } from 'react';
import { RadioGroup, FormControl, FormControlLabel, FormLabel, Radio } from '@mui/material';
import { SCRadioButtonLabel, SCRadioButtonWrapper, SCRadioGroup, SCFormLabel, SCRadio } from './styles';
import { ITSRadioGroupProps } from './types';

const TSRadioGroup = ({ id, radioGroupLabel, radioGroupValues, onChange, value }: ITSRadioGroupProps): ReactElement => {
    return (
        <>
            <SCRadioButtonWrapper>
                <SCFormLabel>
                    <FormLabel>{radioGroupLabel}</FormLabel>
                </SCFormLabel>
                <SCRadioGroup>
                    <RadioGroup onChange={onChange} value={value || null}>
                        <FormControl>
                            <SCRadio>
                                {radioGroupValues?.map(function (element) {
                                    return (
                                        <SCRadioButtonLabel key={element.value}>
                                            <FormControlLabel
                                                name={radioGroupLabel}
                                                data-testid={`radio-label-${element.value}`}
                                                value={element.value}
                                                control={<Radio id={id} />}
                                                label={element.label}
                                            />
                                        </SCRadioButtonLabel>
                                    );
                                })}
                            </SCRadio>
                        </FormControl>
                    </RadioGroup>
                </SCRadioGroup>
            </SCRadioButtonWrapper>
        </>
    );
};

export { TSRadioGroup };
