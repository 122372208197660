import React, { useRef, useState, useEffect, ReactElement } from 'react';
import {
    SCPDFViewerComponentWrapper,
    SCPDFControlsWrapper,
    SCRenderedPDFWrapper,
    SCDownloadLink,
    SCPaginationWrapperDesktop,
    SCPaginationWrapperMobile,
    SCPage
} from './styles';
import { ITSPdfViewerComponentProps } from './types';
import { Pagination } from '@mui/material';
import { Document } from 'react-pdf';
import { TSButton } from 'components/atoms/TSButton';
import {
    TSButtonLetterSpacingEnum,
    TSButtonSizeEnum,
    TSButtonVariantsEnum,
    TSButtonWeightEnum
} from 'enums/TSButtonVariants';

const TSPdfViewerComponent = ({
    pdfFileUri,
    downloadLabel,
    hideDownload = false
}: ITSPdfViewerComponentProps): ReactElement => {
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [renderedPageNumber, setRenderedPageNumber] = useState<null | number>(null);
    const [pdfWidth, setPdfWidth] = useState(1);
    const maxPDFWidth = 842;
    const divRef = useRef<any>();
    const onDocumentLoadSuccess = ({ numPages }): any => {
        setNumPages(numPages);
        updatePageWidth();
    };
    const changePage = (offset: number): void => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };
    const previousPage = (): void => {
        changePage(-1);
    };
    const nextPage = (): void => {
        changePage(1);
    };
    const isLoading = renderedPageNumber !== pageNumber;

    const updatePageWidth = (): void => {
        const newWidth = divRef?.current?.clientWidth ? Math.min(maxPDFWidth, divRef.current.clientWidth) : maxPDFWidth;
        setPdfWidth(newWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updatePageWidth);
    }, []);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::TSPdfViewerComponent:::::::::::::::::::::::::::::::::',
    //     '\n::numPages::',
    //     numPages,
    //     '\n::pageNumber::',
    //     pageNumber,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCPDFViewerComponentWrapper>
            <SCPDFControlsWrapper width={pdfWidth > 1 ? pdfWidth + 'px' : ''}>
                <TSButton
                    ariaLabel={'download certificate PDF'}
                    size={TSButtonSizeEnum.MEDIUM}
                    letterSpacing={TSButtonLetterSpacingEnum.NARROW}
                    weight={TSButtonWeightEnum.BOLD}
                    tsVariant={TSButtonVariantsEnum.UNDERLINE}
                >
                    <SCDownloadLink shouldHide={hideDownload} href={pdfFileUri} target="_blank" download>
                        {downloadLabel ?? 'Download'}
                    </SCDownloadLink>
                </TSButton>
                {/* If in desktop mode, insert the page controls above the PDF, per our Figma design documentation */}
                <SCPaginationWrapperDesktop>
                    <Pagination
                        count={numPages || 1}
                        defaultPage={1}
                        page={pageNumber}
                        onChange={(_, value): void => {
                            setPageNumber(value);
                        }}
                    ></Pagination>
                </SCPaginationWrapperDesktop>
            </SCPDFControlsWrapper>
            {/* PDF Displayed here */}
            <SCRenderedPDFWrapper>
                <div ref={divRef}>
                    <Document file={pdfFileUri} onLoadSuccess={onDocumentLoadSuccess} renderMode={'canvas'}>
                        {isLoading && renderedPageNumber ? (
                            <SCPage
                                key={renderedPageNumber}
                                className="prevPage"
                                pageNumber={renderedPageNumber}
                                width={pdfWidth}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        ) : null}
                        <SCPage
                            key={pageNumber}
                            width={pdfWidth}
                            pageNumber={pageNumber}
                            onRenderSuccess={(): void => {
                                setRenderedPageNumber(pageNumber);
                                updatePageWidth();
                            }}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                        />
                    </Document>
                </div>
            </SCRenderedPDFWrapper>
            {/* If in mobile, insert the page controls below the PDF, per our Figma design documentation */}
            <SCPaginationWrapperMobile>
                <Pagination
                    count={numPages || 1}
                    defaultPage={1}
                    page={pageNumber}
                    onChange={(_, value): void => {
                        setPageNumber(value);
                    }}
                    sx={{ margin: 'auto' }} /* Mobile page controls should be centered */
                ></Pagination>
            </SCPaginationWrapperMobile>
        </SCPDFViewerComponentWrapper>
    );
};

export { TSPdfViewerComponent };
