import React, { ReactElement, SyntheticEvent } from 'react';
import { SCButtonWrapper, SCRootWrapper } from './styles';
import { ITSFormProgressStepperProps } from './types';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { ClaimTypesEnum, TimelineState } from 'enums';
import { ClaimStateEnum } from 'enums/ClaimState';
import { ClaimOptionContext, IClaimOptionTypeState } from 'context/ClaimOption';
import { ClaimProgressJobLossContext, IClaimProgressJobLossTypeState } from 'context/ClaimProgressJobLoss';
import { ClaimProgressDisabilityContext, IClaimProgressDisabilityTypeState } from 'context/ClaimProgressDisability';

const TSFormProgressStepper = ({ children }: ITSFormProgressStepperProps): ReactElement => {
    const {
        updateContextStateClaimProgressJobLoss,
        contextStateClaimProgressJobLoss,
        resetContextStateClaimProgressJobLoss,
        updateClaimProgressJobLossCompletionStatus,
        claimProgressJobLossCompletionStatus,
        updateAllClaimProgressJobLossCompletionStatus
    } = React.useContext(ClaimProgressJobLossContext) as IClaimProgressJobLossTypeState;
    const {
        contextStateClaimProgressDisability,
        updateContextStateClaimProgressDisability,
        resetContextStateClaimProgressDisability,
        claimProgressDisabilityCompletionStatus,
        updateClaimProgressDisabilityCompletionStatus,
        updateAllClaimProgressDisabilityCompletionStatus
    } = React.useContext(ClaimProgressDisabilityContext) as IClaimProgressDisabilityTypeState;
    const { contextStateClaimOption, updateContextStateClaimOption } = React.useContext(
        ClaimOptionContext
    ) as IClaimOptionTypeState;
    const currentFormState =
        contextStateClaimOption.type === ClaimTypesEnum.Jobloss
            ? contextStateClaimProgressJobLoss
            : contextStateClaimProgressDisability;
    /*
    console.info(
        '\n::::::::::::::::::::::::::::::::TSFormProgressStepper:::::::::::::::::::::::::::::::::',
        '\n::contextStateClaimOption.type::',
        contextStateClaimOption.type,
        '\n::currentFormState::',
        currentFormState,
        '\n::contextStateClaimProgressJobLoss::',
        contextStateClaimProgressJobLoss,
        '\n::contextStateClaimProgressDisability::',
        contextStateClaimProgressDisability,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );
    */

    return (
        <SCRootWrapper>
            {currentFormState.map((element: { status: string; label: string }, index) => {
                return (
                    <SCButtonWrapper key={`keyValue${index}`}>
                        <TSButton
                            tsVariant={TSButtonVariantsEnum.STEPPER}
                            ariaLabel={'button for both indicating step state and step navigation'}
                            disabled={element.status === TimelineState.INCOMPLETE}
                            onClick={(event: SyntheticEvent<Element, Event>): void => {
                                if (
                                    contextStateClaimOption.type === ClaimTypesEnum.Jobloss &&
                                    element.status !== TimelineState.INCOMPLETE
                                ) {
                                    updateContextStateClaimOption({
                                        type: ClaimTypesEnum.Jobloss,
                                        reviewState: ClaimStateEnum.ACTIVE
                                    });
                                    contextStateClaimProgressJobLoss.map(
                                        (state) =>
                                            state.status === TimelineState.ACTIVE &&
                                            (state.status = TimelineState.COMPLETE)
                                    );
                                    updateContextStateClaimProgressJobLoss(`id-${index}`, TimelineState.COMPLETE);
                                    updateContextStateClaimProgressJobLoss(`id-${index + 1}`, TimelineState.ACTIVE);
                                }
                                if (
                                    contextStateClaimOption.type === ClaimTypesEnum.Disability &&
                                    element.status !== TimelineState.INCOMPLETE
                                ) {
                                    updateContextStateClaimOption({
                                        type: ClaimTypesEnum.Disability,
                                        reviewState: ClaimStateEnum.ACTIVE
                                    });
                                    contextStateClaimProgressDisability.map(
                                        (state) =>
                                            state.status === TimelineState.ACTIVE &&
                                            (state.status = TimelineState.COMPLETE)
                                    );
                                    updateContextStateClaimProgressDisability(`id-${index}`, TimelineState.COMPLETE);
                                    updateContextStateClaimProgressDisability(`id-${index + 1}`, TimelineState.ACTIVE);
                                }
                            }}
                            stepValue={index + 1}
                            status={element.status}
                        ></TSButton>
                        {children}
                    </SCButtonWrapper>
                );
            })}
        </SCRootWrapper>
    );
};

export { TSFormProgressStepper };
