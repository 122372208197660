import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { ClaimOptionContext, IClaimOptionTypeState } from 'context/ClaimOption';
import { IFormIUData } from 'types/form';
import { IFormNavigationProps } from './types';
import { SCButtonWrapper, SCElementsWrapper, SCRowWrapper } from './styles';
import { TimelineState } from 'enums/TimeLine';
import { ClaimTypesEnum } from 'enums/ClaimTypes';
import { allFieldsComplete, allFieldsValid } from 'utils/form/form';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { ClaimStateEnum } from 'enums/ClaimState';
import { ClaimFormAdvancementContext, IClaimFormAdvancementTypeState } from 'context/ClaimFormAdvancement';
import { TSDialog } from 'components/atoms/TSDialog';
import {
    $digitalBlack,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCSectionBody,
    SCSectionSubHead
} from 'styles/global-styles';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum } from 'enums/StyleTypes';
import { ClaimFormDisabilityContext, IClaimFormDisabilityTypeState } from 'context/ClaimFormDisability';
import { ClaimFormJobLossContext, IClaimFormJobLossTypeState } from 'context/ClaimFormJobLoss';
import { ClaimProgressJobLossContext, IClaimProgressJobLossTypeState } from 'context/ClaimProgressJobLoss';
import { ClaimProgressDisabilityContext, IClaimProgressDisabilityTypeState } from 'context/ClaimProgressDisability';

const FormNavigation = ({
    formData,
    requiredFields,
    validationFields,
    isFormDirty
}: IFormNavigationProps): ReactElement => {
    const {
        updateContextStateClaimProgressJobLoss,
        contextStateClaimProgressJobLoss,
        resetContextStateClaimProgressJobLoss,
        updateClaimProgressJobLossCompletionStatus,
        claimProgressJobLossCompletionStatus,
        updateAllClaimProgressJobLossCompletionStatus
    } = React.useContext(ClaimProgressJobLossContext) as IClaimProgressJobLossTypeState;
    const {
        contextStateClaimProgressDisability,
        updateContextStateClaimProgressDisability,
        resetContextStateClaimProgressDisability,
        claimProgressDisabilityCompletionStatus,
        updateClaimProgressDisabilityCompletionStatus,
        updateAllClaimProgressDisabilityCompletionStatus
    } = React.useContext(ClaimProgressDisabilityContext) as IClaimProgressDisabilityTypeState;
    const { contextStateClaimOption, updateContextStateClaimOption } = React.useContext(
        ClaimOptionContext
    ) as IClaimOptionTypeState;
    const { contextStateClaimFormAdvancement, updateContextStateClaimFormAdvancement } = React.useContext(
        ClaimFormAdvancementContext
    ) as IClaimFormAdvancementTypeState;
    const {
        contextStateClaimFormJobLoss,
        updateContextStateClaimFormJobLoss,
        reinitialiseContextStateClaimFormJobLoss
    } = React.useContext(ClaimFormJobLossContext) as IClaimFormJobLossTypeState;
    const {
        contextStateClaimFormDisability,
        updateContextStateClaimFormDisability,
        reinitialiseStateClaimFormDisability
    } = React.useContext(ClaimFormDisabilityContext) as IClaimFormDisabilityTypeState;
    const [formValid, setFormValid] = useState<boolean>(false);
    const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
    useEffect(() => {
        if (formData && requiredFields) {
            setFormValid(allFieldsComplete(requiredFields, formData) && allFieldsValid(validationFields));
        }
    }, [formData, requiredFields, validationFields]);
    const handleSaveFormState = (event: React.FormEvent, formData: IFormIUData | any): any => {
        contextStateClaimOption.type === ClaimTypesEnum.Jobloss
            ? updateContextStateClaimFormJobLoss(formData)
            : updateContextStateClaimFormDisability(formData);
    };
    const currentId =
        contextStateClaimOption.type === ClaimTypesEnum.Jobloss
            ? contextStateClaimProgressJobLoss
                  .filter((item) => item.status === TimelineState.ACTIVE)
                  .map((item) => item.id)
            : contextStateClaimProgressDisability
                  .filter((item) => item.status === TimelineState.ACTIVE)
                  .map((item) => item.id);
    const incrementId = currentId
        ? currentId[0].replace(/\d+$/, (n): string => {
              return (parseInt(n) + 1) as unknown as string;
          })
        : '';
    const decrementId = currentId
        ? currentId[0].replace(/\d+$/, (n): string => {
              return (parseInt(n) - 1) as unknown as string;
          })
        : '';
    const handleBackFormStates = (): void => {
        if (currentId[0] === 'id-1') {
            updateContextStateClaimOption({
                type: undefined,
                reviewState: ClaimStateEnum.ACTIVE
            });
            updateContextStateClaimProgressJobLoss(currentId[0], TimelineState.COMPLETE);
            updateContextStateClaimProgressDisability(currentId[0], TimelineState.COMPLETE);
            setConfirmModalIsVisible(true);
        }
        if (contextStateClaimOption.type === ClaimTypesEnum.Jobloss) {
            updateContextStateClaimProgressJobLoss(decrementId, TimelineState.ACTIVE);
            updateContextStateClaimProgressJobLoss(currentId[0], TimelineState.COMPLETE);
        } else if (contextStateClaimOption.type === ClaimTypesEnum.Disability) {
            updateContextStateClaimProgressDisability(decrementId, TimelineState.ACTIVE);
            updateContextStateClaimProgressDisability(currentId[0], TimelineState.COMPLETE);
        }
    };
    /* console.info(
        '\n::::::::::::::::::::::::::::::::FormNavigation:::::::::::::::::::::::::::::::::',
        '\n::currentId::',
        currentId,
        '\n::incrementId::',
        incrementId,
        '\n::decrementId::',
        decrementId,
        '\n::formValid::',
        formValid,
        '\n::contextStateClaimOption::',
        '\n::contextStateClaimOption::',
        contextStateClaimOption,
        '\n::contextStateClaimFormDisability::',
        contextStateClaimFormDisability,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );*/

    return (
        <SCElementsWrapper>
            <SCRowWrapper>
                <SCButtonWrapper>
                    <TSButton
                        tsVariant={TSButtonVariantsEnum.BACK}
                        onClick={(event: SyntheticEvent<Element, Event>): void => {
                            isFormDirty && currentId[0] === 'id-1'
                                ? setConfirmModalIsVisible(true)
                                : handleBackFormStates();
                        }}
                        ariaLabel={'return to previous view'}
                    >
                        Back
                    </TSButton>
                </SCButtonWrapper>
                <SCButtonWrapper>
                    <TSButton
                        tsVariant={TSButtonVariantsEnum.ARROW}
                        ariaLabel={''}
                        disabled={!formValid}
                        onClick={(event: SyntheticEvent<Element, Event>): void => {
                            handleSaveFormState(event, formData);
                            if (allFieldsComplete(requiredFields, formData)) {
                                if (contextStateClaimOption.type === ClaimTypesEnum.Jobloss) {
                                    updateContextStateClaimProgressJobLoss(currentId[0], TimelineState.COMPLETE);
                                    updateContextStateClaimProgressJobLoss(incrementId, TimelineState.ACTIVE);
                                }
                                if (contextStateClaimOption.type === ClaimTypesEnum.Disability) {
                                    updateContextStateClaimProgressDisability(currentId[0], TimelineState.COMPLETE);
                                    updateContextStateClaimProgressDisability(incrementId, TimelineState.ACTIVE);
                                }
                                if (
                                    contextStateClaimProgressJobLoss.every((obj) => {
                                        return obj.status === TimelineState.COMPLETE;
                                    })
                                ) {
                                    updateContextStateClaimOption({
                                        type: ClaimTypesEnum.Jobloss,
                                        reviewState: ClaimStateEnum.REVIEW
                                    });
                                    updateContextStateClaimFormAdvancement({
                                        reviewState: ClaimStateEnum.REVIEW
                                    });
                                }
                                if (
                                    contextStateClaimProgressDisability.every((obj) => {
                                        return obj.status === TimelineState.COMPLETE;
                                    })
                                ) {
                                    updateContextStateClaimOption({
                                        type: ClaimTypesEnum.Disability,
                                        reviewState: ClaimStateEnum.REVIEW
                                    });
                                    updateContextStateClaimFormAdvancement({
                                        reviewState: ClaimStateEnum.REVIEW
                                    });
                                }
                            }
                        }}
                    >
                        Continue
                    </TSButton>
                </SCButtonWrapper>
            </SCRowWrapper>
            {((contextStateClaimOption.type === ClaimTypesEnum.Disability && claimProgressDisabilityCompletionStatus) ||
                (contextStateClaimOption.type === ClaimTypesEnum.Jobloss && claimProgressJobLossCompletionStatus)) && (
                <SCRowWrapper>
                    <SCButtonWrapper>
                        <TSButton
                            tsVariant={TSButtonVariantsEnum.BACK}
                            disabled={!formValid}
                            onClick={(event: SyntheticEvent<Element, Event>): void => {
                                handleSaveFormState(event, formData);
                                if (allFieldsComplete(requiredFields, formData)) {
                                    if (contextStateClaimOption.type === ClaimTypesEnum.Jobloss) {
                                        updateClaimProgressJobLossCompletionStatus(true);
                                        updateContextStateClaimOption({
                                            type: ClaimTypesEnum.Jobloss,
                                            reviewState: ClaimStateEnum.REVIEW
                                        });
                                        updateContextStateClaimFormAdvancement({
                                            reviewState: ClaimStateEnum.REVIEW
                                        });
                                        updateAllClaimProgressJobLossCompletionStatus();
                                    }
                                    if (contextStateClaimOption.type === ClaimTypesEnum.Disability) {
                                        updateClaimProgressDisabilityCompletionStatus(true);
                                        updateContextStateClaimOption({
                                            type: ClaimTypesEnum.Disability,
                                            reviewState: ClaimStateEnum.REVIEW
                                        });
                                        updateContextStateClaimFormAdvancement({
                                            reviewState: ClaimStateEnum.REVIEW
                                        });
                                        updateAllClaimProgressDisabilityCompletionStatus();
                                    }
                                }
                            }}
                            ariaLabel={'return to previous view'}
                        >
                            Return to Review
                        </TSButton>
                    </SCButtonWrapper>
                </SCRowWrapper>
            )}
            <TSDialog
                ariaLabel={'Unsaved Changes dialog'}
                hideSecondaryCloseButton
                handleClose={(): void => {
                    setConfirmModalIsVisible(false);
                }}
                id={'unsavedChangesDialog'}
                open={confirmModalIsVisible}
                dialogSize={'sm'}
                bannerHeaderCloseButtonText={'Close'}
                content={
                    <SCContentSectionWrapper
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                        flexGap={'24px'}
                    >
                        <SCSectionSubHead textColor={$digitalBlack}>Unsaved Changes</SCSectionSubHead>
                        <SCSectionBody>
                            You have unsaved claim information, continuing may cause your information to be lost
                        </SCSectionBody>
                    </SCContentSectionWrapper>
                }
                actionChildren={
                    <SCContentSectionResponsiveWrapper
                        flexJustifyContent={FlexJustifyContentEnum.SPACE_AROUND}
                        flexAlignment={FlexAlignItemsEnum.CENTER}
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexGap={'16px'}
                    >
                        <TSButton
                            ariaLabel="dialog return to page"
                            tsVariant={TSButtonVariantsEnum.TEXT}
                            size={TSButtonSizeEnum.MEDIUM}
                            onClick={(): void => {
                                setConfirmModalIsVisible(false);
                            }}
                        >
                            Return to page
                        </TSButton>
                        <TSButton
                            ariaLabel="dialog proceed without saving"
                            tsVariant={TSButtonVariantsEnum.ARROW}
                            size={TSButtonSizeEnum.MEDIUM}
                            onClick={(): void => {
                                handleBackFormStates();
                                setConfirmModalIsVisible(false);
                            }}
                        >
                            Proceed without saving
                        </TSButton>
                    </SCContentSectionResponsiveWrapper>
                }
            ></TSDialog>
        </SCElementsWrapper>
    );
};

export { FormNavigation };
